import { useContext, useEffect, useState } from "react"
import { CalendarsContext, ContactsContext, EventsContext } from "context"
import { useParams, useSearchParams } from "react-router-dom"

// @mui material components
import { Stack, Divider } from "@mui/material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"

// Components
import ContactOverview from "forge/people/contacts/contact-overview"
import EventDrawer from "../components/CreateEventDrawer"
import { CalendarEvent } from "types/calendar/calendar-event"
import Footer from "examples/Footer"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import EventDetailCard from "../components/EventDetailCard"
import ForgeCta from "forge/core/components/ForgeCta"
import { EmailOutlined, PersonSearchOutlined } from "@mui/icons-material"
import MDTypography from "components/MDTypography"
import { CalendarTypeExtended } from "types/calendar/calendar"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

function EventOverview() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  // Context
  const { events, getEvent } = useContext(EventsContext)
  const { getCalendar, isCalendarWritable } = useContext(CalendarsContext)
  const { getDraftContactsByEmail, changeContactDraftStatus } = useContext(ContactsContext)

  // State
  const [event, setEvent] = useState<CalendarEvent | null>()
  const [selectedContact, setSelectedContact] = useState<any>(null)
  const [possibleContacts, setPossibleContacts] = useState([])
  const [isSelectedContactDraft, setIsSelectedContactDraft] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [allLoading, setAllLoading] = useState(false)
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)
  // const [openAddAllDialog, setOpenAddAllDialog] = useState(false)
  // const handleOpenAddAllDialog = () => setOpenAddAllDialog(true)

  const handleOpenDrawer = () => setOpenCreateEventDrawer(true)
  const handleCloseDrawer = () => setOpenCreateEventDrawer(false)

  useEffect(() => {
    if (!id) return

    const calendarEvent = getEvent(id)
    setEvent(calendarEvent)

    if (calendarEvent?.properties?.attendees?.length > 0) {
      const draftAttendes = calendarEvent.properties.attendees.filter(attendee => !attendee.contact)
      setPossibleContacts(getDraftContactsByEmail(draftAttendes.map(attendee => attendee.emailAddress)))
    }

    // Set initial contact
    if (calendarEvent?.contacts.length > 0 && !selectedContact) {
      setSelectedContact(calendarEvent?.contacts[0])
    } else if (possibleContacts.length > 0 && !selectedContact) {
      clickContact(possibleContacts[0], true)
    } else {
      setSelectedContact(undefined)
    }
  }, [id, events])

  useEffect(() => {
    // Used for when reloading the app
    // It's possible for the events to load faster than contacts, so if the user refreshes the page the event will come without a contact
    // This will ensure the contact is set correctly
    if (event?.contacts?.length > 0) {
      setSelectedContact(event?.contacts[0])
    } else if (possibleContacts.length > 0) {
      clickContact(possibleContacts[0], true)
    } else {
      setSelectedContact(undefined)
    }
  }, [event?.contacts?.length])

  const clickContact = (contact: any, isDraft = false) => {
    setSelectedContact(contact)
    setIsSelectedContactDraft(isDraft)
  }

  const addDraftContact = async (contact: any) => {
    setLoading(true)
    await changeContactDraftStatus([contact?.ref])
    setEvent(prevValue => {
      prevValue.contacts.push(contact)
      return prevValue
    })
    setPossibleContacts(possibleContacts.filter(e => e.ref.id !== contact.ref.id))
    setIsSelectedContactDraft(false)
    setLoading(false)
  }

  // const handleCloseClearDialog = async (result: boolean) => {
  //   setOpenAddAllDialog(false)
  //   if (result) {
  //     // Add all
  //     setAllLoading(true)
  //     const contactRefs = possibleContacts.map(draftContact => draftContact.ref)
  //     await changeContactDraftStatus(contactRefs)
  //     setEvent(prevValue => {
  //       prevValue.contacts.push(...possibleContacts)
  //       return prevValue
  //     })
  //     setPossibleContacts([])
  //     setIsSelectedContactDraft(false)
  //     setAllLoading(false)
  //   }
  // }

  const getBackSteps = () => {
    let backSteps = searchParams.get("backSteps")
    if (backSteps && !isNaN(parseInt(backSteps))) {
      return parseInt(backSteps)
    }

    return -1
  }

  const getContactAttendeeValue = (contact: any) => {
    // First try to look based on the ref
    let attendee = event.properties.attendees.find(
      e => e?.ref?.id === contact?.ref?.id || contact.emailStrings?.some((email: string) => email === e.emailAddress)
    )
    if (!attendee) {
      attendee = event.properties.attendeesResearch.find(
        e => e?.ref?.id === contact?.ref?.id || contact.emailStrings?.some((email: string) => email === e.emailAddress)
      )
      if (!attendee) {
        let attendeeIndex = event.properties.attendeesRefs?.map(ref => ref.id).indexOf(contact.ref?.id)

        if (attendeeIndex !== -1 && event.properties.attendees.length > attendeeIndex) {
          attendee = event.properties.attendees[attendeeIndex]
        } else {
          let attendeeIndex = event?.properties?.attendeesResearchRefs?.map(ref => ref.id).indexOf(contact.ref?.id)
          if (attendeeIndex !== -1 && event.properties.attendeesResearch.length > attendeeIndex) {
            attendee = event.properties.attendeesResearch[attendeeIndex]
          }
        }
      }
    }

    return attendee
  }

  return (
    <DashboardLayout>
      <EventDrawer openDrawer={openCreateEventDrawer} handleCloseDrawer={handleCloseDrawer} event={event} setEvent={setEvent} />
      {/* <ConfirmationDialog
        openDialog={openAddAllDialog}
        handleCloseDialog={handleCloseClearDialog}
        title="Are you sure you want to add all as new contacts?"
        description=""
        confirmText="Add all"
        denyVariant="outlined"
      /> */}
      <MDBox mb={3}>
        <EventDetailCard event={event} handleOpenDrawer={handleOpenDrawer} backSteps={getBackSteps()} />
        <MDBox mt={2} />
        <MDBox display="flex" alignItems="center">
          {event?.contacts?.length > 0 || possibleContacts.length > 0 ? (
            <>
              {/* Actual user contacts */}
              {event?.contacts.map((contact: any) => {
                let attendee

                if (event?.properties) attendee = getContactAttendeeValue(contact)

                return (
                  <div style={{ margin: "0rem 1rem 1rem 0rem", cursor: "pointer" }} key={contact.ref?.id}>
                    <ForgeContactAvatar
                      contact={contact}
                      attendee={attendee}
                      diameter={50}
                      enableScoreBadge={false}
                      onContactTapped={() => clickContact(contact)}
                    />
                  </div>
                )
              })}
              {/* Draft contacts that are part of the meeting */}
              {possibleContacts.map((contact: any) => {
                let attendee
                if (event?.properties) {
                  attendee = event.properties.attendees.find(e => e?.emailAddress === contact?.emailStrings[0])
                  if (!attendee) attendee = event.properties.attendeesResearch.find(e => e?.emailAddress === contact?.emailStrings[0])
                }

                return (
                  <div style={{ margin: "0rem 1rem 1rem 0rem", cursor: "pointer" }} key={contact.ref?.id}>
                    <ForgeContactAvatar
                      contact={contact}
                      attendee={attendee}
                      diameter={50}
                      enableScoreBadge={false}
                      onContactTapped={() => clickContact(contact, true)}
                    />
                  </div>
                )
              })}
              {/* If draft contacts are more than 1 include the add all button  */}
              {/* {possibleContacts.length > 1 && !allLoading && (
                <Tooltip title="Add all found contacts" placement="right">
                  <IconButton color="primary" size="large" style={{ marginBottom: "auto" }} onClick={() => handleOpenAddAllDialog()}>
                    <GroupAdd />
                  </IconButton>
                </Tooltip>
              )} */}
              <ForgeLoading loading={loading} loadingType="small" style={{ marginBottom: "12px" }} />
            </>
          ) : event?.isEventEditable(isCalendarWritable) ? (
            <Stack direction="column" spacing={4} style={{ width: "100%", marginTop: "24vh", marginBottom: "24vh" }}>
              <ForgeCta cta="Invite People by Email" icon={<EmailOutlined />} onClick={handleOpenDrawer} />
              <Divider style={{ opacity: 1 }} />
              <ForgeCta cta="Add People for research only" icon={<PersonSearchOutlined />} onClick={handleOpenDrawer} />
            </Stack>
          ) : (
            <Stack direction="column" spacing={4} style={{ width: "100%", marginTop: "32vh", marginBottom: "32vh" }}>
              <MDTypography variant="body2" fontWeight="medium" align="center" style={{ color: "darkgray" }}>
                Sorry, {CalendarTypeExtended.getName(getCalendar(event?.calendarId)?.type)} doesn't allow this event to be edited in Forge.
              </MDTypography>
            </Stack>
          )}
        </MDBox>
        {selectedContact && (
          <ContactOverview contactId={selectedContact?.id} isDraftContact={isSelectedContactDraft} addDraftContact={addDraftContact} />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default EventOverview
