import { Close, Done, KeyboardArrowDownRounded, KeyboardArrowUpRounded, LocationOn, Notes, QuestionMarkRounded, Rsvp } from "@mui/icons-material"
import { AppBar, Avatar, Card, Collapse, Icon, IconButton, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import { AuthContext, CalendarsContext, EventsContext, setTransparentNavbar, useMaterialUIController } from "context"
import { navbar } from "examples/Navbars/DashboardNavbar/styles"
import EventsApi from "forge/calendar/services/api"
import { useContext, useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { CalendarEvent } from "types/calendar/calendar-event"
import { InvitationStatus, InvitationStatusExtended } from "types/calendar/forge-attendee"
import { VideoConferencingPlatformExtended } from "types/calendar/video-conferencing-platform"

// Images
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import BackButton from "forge/core/components/BackButton"
import { hasMeetingUrl, hasPhysicalLocation } from "types/forge-event-extension"
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer"
import { Call } from "types/pipeline/call"
import MDSnackbar from "components/MDSnackbar"
import { ColorType, SnackbarProps } from "types/snackbar"
import { createPortal } from "react-dom"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { Timestamp } from "firebase/firestore"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px", paddingBottom: "2px" }
})

function EventDetailCard({ handleOpenDrawer, event, backSteps = -1 }: { handleOpenDrawer: () => void; event: CalendarEvent; backSteps?: number }) {
  // UI
  const classes = styles()

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { getWritableCalendars, isCalendarWritable } = useContext(CalendarsContext)
  const { getEventCall } = useContext(EventsContext)

  // API
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const eventsApi = new EventsApi(user, userProfileData, encryptionService)
  const [loading, setLoading] = useState<boolean>(false)

  // State
  const previousScrollY = useRef(0)
  const [eventCall, setEventCall] = useState<Call>()
  const [collapseDetail, setCollapseDetail] = useState<boolean>(true)
  const [collapseRsvp, setCollapseRsvp] = useState<boolean>(true)
  const [navbarType, setNavbarType] = useState<"fixed" | "absolute" | "relative" | "static" | "sticky">()
  const [openCreateCallDrawer, setOpenCreateCallDrawer] = useState(false)
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const [preselectedRsvp, setPreselectedRsvp] = useState<InvitationStatus>()

  const handleOpenCallDrawer = () => setOpenCreateCallDrawer(true)
  const handleCloseCallDrawer = () => setOpenCreateCallDrawer(false)

  // Snackbar
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    open: false,
    color: "info",
    title: "",
    message: ""
  })

  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller

  const handleOpenDetail = () => setCollapseDetail(!collapseDetail)
  const handleOpenRsvp = () => setCollapseRsvp(!collapseRsvp)

  const renderDate = () => {
    const startDate = event?.startDate?.toDate();
    const endDate = event?.endDate?.toDate();
    
    if (!startDate || !endDate) return;
  
    const formatOptions: any = {
      weekday: "long",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    };
  
    const startDay = startDate.toLocaleDateString("en-US", formatOptions);
    const endDay = endDate.toLocaleDateString("en-US", formatOptions);
  
    if (event.isAllDay) {
      return isExactAllDay(event.startDate, event.endDate)
        ? `${startDate.toLocaleDateString("en-US", { weekday: "long", month: "short", day: "2-digit" })}, All day`
        : `${startDay} - ${endDay}`;
    }
  
    return startDate.toDateString() === endDate.toDateString()
      ? `${startDay} - ${endDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })}`
      : `${startDay} - ${endDay}`;
  };

  const isExactAllDay = (start: Timestamp, end: Timestamp) => {
    const endDate = end?.toDate() || start.toDate()
    const startDate = start.toDate()
    const differenceInMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60)
    return differenceInMinutes >= 1439 && differenceInMinutes <= 1441
  }
  
  const renderDescription = (description?: string) => {
    if (!description) return description

    let hasHTML = /<\/?[a-z][\s\S]*>/i.test(description)
    if (hasHTML) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: description
          }}
          style={{ fontSize: "small", textAlign: "left" }}
        />
      )
    } else {
      return (
        <MDTypography variant="body2" fontSize="small" sx={{ textAlign: "left" }} style={{ whiteSpace: "pre-wrap" }}>
          {description}
        </MDTypography>
      )
    }
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky")
    } else {
      setNavbarType("static")
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY <= 25) || !fixedNavbar)
    }

    /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
        */
    window.addEventListener("scroll", handleTransparentNavbar)

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar)
  }, [dispatch, fixedNavbar, miniSidenav])

  useEffect(() => {
    if (event?.callRef) {
      ;(async () => {
        setEventCall(await getEventCall(event))
      })()
    }
  }, [event])

  const getHeight = () => {
    let height = 0
    if (window.scrollY > 25 && window.scrollY < 95 && previousScrollY.current <= window.scrollY) {
      height = 70
      if (!transparentNavbar) {
      }
    }

    console.log("scrollY", window.scrollY, "height", height, "transparentNavbar", transparentNavbar)
    if (!transparentNavbar) {
      previousScrollY.current = window.scrollY
    }
    return height
  }

  const handleCloseDialogDelete = async (result: boolean) => {
    setOpenDialogDelete(false)
    // If all events, true
    await updateRsvp(preselectedRsvp, result)
    setPreselectedRsvp(null)
  }

  const checkEventType = async (status: InvitationStatus) => {
    if(!event?.recurrence?.recurrent) {
      await updateRsvp(status)
      return
    }

    setPreselectedRsvp(status)
    setOpenDialogDelete(true)
  }

  const updateRsvp = async (status: InvitationStatus, affectAllRecurrentEvents?: boolean) => {
    setCollapseRsvp(true)
    setLoading(true)

    event.properties.userAttendee.status = status
    var calendar = getWritableCalendars().find(e => e.id === event.calendarId)
    await eventsApi.updateEventInvite(event, calendar, affectAllRecurrentEvents)

    setLoading(false)
  }

  const onMeetingAction = () => {
    if (hasPhysicalLocation(event)) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${event.properties.location}`, "_blank")
    }

    let url = event?.properties?.webUrl ?? event?.properties?.url

    if (url) {
      window.open(url, "_blank")
    }
  }

  const handleSnackbar = (color: ColorType, title: string, message: string) => {
    setSnackbar({
      open: true,
      color,
      title,
      message
    })
  }

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }))
  }

  const renderSnackbar = createPortal(
    <MDSnackbar
      color={snackbar.color}
      icon="notifications"
      dateTime=""
      title={snackbar.title}
      content={snackbar.message}
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      close={handleCloseSnackbar}
      bgWhite
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: 1500
      }}
    />,
    document.body
  )

  return (
    <>
        <ConfirmationDialog
          openDialog={openDialogDelete}
          handleCloseDialog={handleCloseDialogDelete}
          title="RSVP to recurring event"
          description=""
          confirmText="All events"
          denyText="This event only"
        />
      {renderSnackbar}
      <AppBar
        position={navbarType}
        color="inherit"
        style={{ padding: 0, zIndex: 2, overflow: "hidden" }}
        sx={theme => navbar(theme, { transparentNavbar })}
      >
        <Card id="event-detail" style={{ background: "white" }}>
          <CreateCallDrawer
            openDrawer={openCreateCallDrawer}
            handleCloseDrawer={handleCloseCallDrawer}
            call={eventCall}
            event={event}
            showSnackbar={handleSnackbar}
          />
          <MDBox mx={3} mt={3} display={"flex"} alignItems={"start"}>
            <BackButton backSteps={backSteps} style={{ marginTop: miniSidenav ? "2rem" : "0" }} />
            <MDBox flex={1} />
            <MDBox>
              {transparentNavbar ? (
                <>
                  <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    sx={{
                      textAlign: "center"
                    }}
                    style={{
                      textDecoration: event?.isDeclined ? "line-through" : "none",
                      color: event?.isDeclined ? "#80808096" : "black"
                    }}
                  >
                    {event?.name}
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    mb={2}
                    sx={{
                      textAlign: "center"
                    }}
                    style={{
                      textDecoration: event?.isDeclined ? "line-through" : "none",
                      color: event?.isDeclined ? "#80808096" : "black"
                    }}
                  >
                    {renderDate()}
                  </MDTypography>
                </>
              ) : (
                <MDBox height={getHeight()} />
              )}
              {hasPhysicalLocation(event) ? (
                <Stack>
                  <MDTypography variant="body2" sx={{ textAlign: "center" }} mb={2}>
                    {event.properties.location}
                  </MDTypography>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDButton variant="outlined" color="dark" onClick={onMeetingAction}>
                      <MDBox display="flex" justifyContent="center" textAlign="center" style={{ color: "black" }}>
                        <LocationOn style={{ marginRight: "5px", marginTop: "3px" }} fontSize="large" />
                        {"Navigate"}
                      </MDBox>
                    </MDButton>
                  </MDBox>
                </Stack>
              ) : hasMeetingUrl(event) ? (
                <Stack direction="row" justifyContent={"center"}>
                  <MDButton style={{ color: "black" }} variant="outlined" color="dark" onClick={onMeetingAction}>
                    <Stack direction={"row"} alignContent={"center"}>
                      <img
                        src={VideoConferencingPlatformExtended.getIcon(event?.properties.videoConferencingPlatform)}
                        className={classes.formIcon}
                        alt="Join Meeting"
                      />
                      {"Join Meeting"}
                    </Stack>
                  </MDButton>
                  <MDBox width={"8px"} />
                  <MDButton style={{ color: "black" }} variant="outlined" color="dark" onClick={handleOpenCallDrawer}>
                    <Stack direction={"row"} alignContent={"center"} alignItems={"center"}>
                      <Notes fontSize="medium" style={{ marginRight: "8px" }} />
                      {"Add Notes"}
                    </Stack>
                  </MDButton>
                </Stack>
              ) : (
                <MDTypography
                  variant="body2"
                  sx={{
                    textAlign: "center"
                  }}
                  mb={2}
                >
                  No meeting location found
                </MDTypography>
              )}
            </MDBox>
            <MDBox flex={1} />
            <Stack direction={"row"} alignSelf={"start"} justifyContent={"right"} alignItems="right" spacing={1}>
              {event?.isEventEditable(isCalendarWritable) &&
                (loading ? (
                  <ForgeLoading loading={loading} loadingType="small" />
                ) : (
                  <IconButton size="large" aria-label="edit" color="inherit" onClick={handleOpenRsvp}>
                    <Rsvp style={{ color: InvitationStatusExtended.color(event?.properties?.userAttendee?.status) }} />
                  </IconButton>
                ))}
              <Collapse in={!collapseRsvp} orientation="horizontal" style={{ padding: 0 }}>
                <Stack direction={"row"} alignSelf={"start"} justifyContent={"right"} alignItems="right" spacing={1}>
                  <Avatar
                    style={{ background: InvitationStatusExtended.color(InvitationStatus.accepted), cursor: "pointer" }}
                    onClick={() => checkEventType(InvitationStatus.accepted)}
                  >
                    <Done />
                  </Avatar>
                  <Avatar
                    style={{ background: InvitationStatusExtended.color(InvitationStatus.maybe), cursor: "pointer" }}
                    onClick={() => checkEventType(InvitationStatus.maybe)}
                  >
                    <QuestionMarkRounded />
                  </Avatar>
                  <Avatar
                    style={{ background: InvitationStatusExtended.color(InvitationStatus.rejected), cursor: "pointer" }}
                    onClick={() => checkEventType(InvitationStatus.rejected)}
                  >
                    <Close />
                  </Avatar>
                </Stack>
              </Collapse>
              <IconButton
                size="small"
                aria-label="edit"
                color="inherit"
                onClick={event?.properties.description?.trim().length > 0 ? handleOpenDetail : undefined}
              >
                {collapseDetail ? (
                  <KeyboardArrowDownRounded
                    fontSize="medium"
                    style={{ color: event?.properties.description?.trim().length > 0 ? undefined : "lightgray" }}
                  />
                ) : (
                  <KeyboardArrowUpRounded fontSize="medium" />
                )}
              </IconButton>
              {event?.isEventEditable(isCalendarWritable) && (
                <IconButton size="small" aria-label="edit" color="inherit" onClick={handleOpenDrawer}>
                  <Icon fontSize="small">edit</Icon>
                </IconButton>
              )}
            </Stack>
          </MDBox>
          <MDBox mx={5} my={1.5}>
            <Collapse in={!collapseDetail} style={{ padding: 0 }}>
              {renderDescription(event?.properties?.description)}
            </Collapse>
          </MDBox>
        </Card>
      </AppBar>
    </>
  )
}

// Setting default values for the props of EventDetailCard
EventDetailCard.defaultProps = {
  handleCloseDrawer: null
}

export default EventDetailCard
