import { firestoreDb } from "firebase.init"
import { DocumentReference, Timestamp, doc } from "firebase/firestore"

export const documentReferenceFromMap = (ref: any) => {
  return ref ? (ref instanceof DocumentReference ? ref : typeof ref === "string" ? doc(firestoreDb, ref) : null) : null
}

export const getDateFromInput = (input: any): Date | null => {
  if (!input) return null;
  
  if (input instanceof Date) {
    return input;
  } else if (typeof input === "number" || typeof input === "string") {
    return new Date(input);
  } else if (typeof Timestamp !== "undefined" && input instanceof Timestamp) {
    return input.toDate();
  } else {
    console.error("Unsupported date type:", input);
    return null;
  }
}

/**
 * Converts the input into a Date and adjusts it based on event type.
 *
 * @param date - The input date (can be a Date, Timestamp, number, or string).
 * @param isAllDay - If true, the function returns the local time version of the date.
 * @param isLongEvent - If true, no adjustments are made.
 * @returns A Date adjusted for the event type or null if invalid.
 */
export const dateFromMap = (
  date: any,
  isAllDay: boolean = false,
  isLongEvent: boolean = false
): Date | null => {
  const d = getDateFromInput(date);
  if (!d || isNaN(d.getTime())) {
    console.error("Invalid date provided:", date);
    return null;
  }

  if (isLongEvent) {
    return d;
  }

  if (isAllDay) {
    // Adjust the UTC date to local time by adding the timezone offset.
    const offsetMs = d.getTimezoneOffset() * 60 * 1000;
    return new Date(d.getTime() + offsetMs);
  }

  return d;
};

export const dateToMap = (date: any) => {
  return date ? (date instanceof Timestamp ? date.toMillis() : date instanceof Date ? date.getTime() : undefined) : undefined
}
