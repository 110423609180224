import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  TextField,
  createFilterOptions
} from "@mui/material"
import MDBox from "components/MDBox"
import { Edit } from "@mui/icons-material"
import { ListenContext } from "../../services/ListenContext"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import MDButton from "components/MDButton"
import { createUseStyles } from "react-jss"
import { DatePicker, LocalizationProvider, YearCalendar } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { Question, QuestionType } from "types/question"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import { ContactsContext } from "context"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { getBachelors } from "types/contact/linkedin-data"
import MDTypography from "components/MDTypography"
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData"

const styles = createUseStyles({
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    },
    "& .Mui-selected": {
      background: "black"
    }
  }
})

function QuestionField({ question, contact }: { question: Question; contact: any }) {
  // UI
  const classes = styles()

  // Context
  const { saveAnswer } = useContext(ListenContext)
  const { contacts } = useContext(ContactsContext)

  // State
  const inputRef = useRef(null)
  const [possibleAnswers, setPossibleAnswers] = useState<string[]>([])
  const [focused, setFocused] = useState(false)
  const focusedRef = useRef(focused)
  const [loading, setLoading] = useState(false)
  const onFocus = () => {
    if (!answer && question.prefill) {
      setAnswer(question.prefill)
    }

    return setFocused(true)
  }
  const onBlur = () => setFocused(false)

  const [answer, setAnswer] = useState<string>()
  const answerRef = useRef(answer)
  const [date, setDate] = useState<Dayjs>()
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean[]>([])
  const [selectedContact, setSelectedContact] = useState<any>()

  const [day, setDay] = useState("??")
  const [month, setMonth] = useState("??")
  const [year, setYear] = useState("??")
  const [daysInMonth, setDaysInMonth] = useState([])
  const [error, setError] = useState("")
  const currentYear = dayjs().year()

  const keyboardListener = useCallback(async (event: any) => {
    if (focusedRef.current) {
      if (event.key === "Escape") {
        handleClear()
      } else if (event.key === "Enter") {
        await handleSave()
        setDate(null)
        clearInput()
      }
    }
  }, [])

  useEffect(() => {
    focusedRef.current = focused
  }, [focused])

  useEffect(() => {
    answerRef.current = answer
  }, [answer])

  useEffect(() => {
    if (question.notNullPredecessors.includes("school1")) {
      const linkedInBachelors = getBachelors(contact?.linkedInProfileData?.education)

      if (!contact?.questionsVars) {
        contact.questionsVars = {}
      }

      contact.questionsVars["school1"] = contact.questionsVars["school1"] ?? linkedInBachelors?.school
    }
  }, [question])

  useEffect(() => {
    document.addEventListener("keydown", keyboardListener, false)

    return () => {
      document.removeEventListener("keydown", keyboardListener, false)
    }
  }, [keyboardListener])

  useEffect(() => {
    if (question.possibleAnswers) {
      // Options
      setPossibleAnswers(question.renderQuestionVars(contact?.questionsVars))

      // Checkbox
      let checkboxState = []
      for (const possibleAnswer of question.possibleAnswers) {
        checkboxState.push(false)
      }
      setSelectedCheckbox(checkboxState)
    }
  }, [question?.possibleAnswers])

  const handleSave = async (answerOverride?: string) => {
    //* Cases to avoid sendind empty data for optionalDatePicker
    if (
      question.type === QuestionType.optionalDatePicker &&
      ((day !== "??" && month === "??" && year === "??") || (day !== "??" && month === "??" && year !== "??"))
    ) {
      return
    }

    if (question.type === QuestionType.optionalDatePicker) {
      onBlur();
    }

    const finalAnswer = answerOverride ?? answerRef.current
    if (finalAnswer?.trim() || selectedContact) {
      setLoading(true)
      await saveAnswer(finalAnswer, question, selectedContact)
      setLoading(false)
      setInputFocus(false)
    }

    setDate(null)
    clearInput()
  }

  const clearInput = () => {
    if (inputRef?.current && question.type !== QuestionType.optionalDatePicker) inputRef.current.blur()

    if (question.type === QuestionType.optionalDatePicker) {
      setDay("??")
      setMonth("??")
      setYear("??")
    }
    setAnswer("")
    setInputFocus(false)
  }

  const setInputFocus = (state: boolean) => {
    const notEmpty = !!inputRef.current?.value

    if (notEmpty) return setFocused(true)

    setFocused(state)
  }

  const updateCheckboxValue = (index: number, newValue: boolean) => {
    setSelectedCheckbox(prevState => {
      // Create a copy of the state array
      const newArray = [...prevState]
      // Update the value at the specified index
      newArray[index] = newValue
      // Return the new array to update the state
      return newArray
    })
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any", // Can be 'anywhere' or 'start'
    stringify: (option: any) => option.name
  })

  useEffect(() => {
    let selectedOptions = []
    for (let i = 0; i < possibleAnswers.length; i++) {
      const option = possibleAnswers[i]
      if (selectedCheckbox[i]) {
        selectedOptions.push(option)
      }
    }

    setAnswer(selectedOptions.join(", "))
  }, [selectedCheckbox])

  const handleClear = async () => {
    if (question.type === QuestionType.people) {
      await handleSave("nobody")
    }
    setDate(null)
    clearInput()
    setFocused(false)
  }

  useEffect(() => {
    if (month === "??" || year === "??") {
      setDaysInMonth([...Array(31).keys()].map(d => d + 1))
    } else {
      const days = dayjs(`${year}-${month}-01`).daysInMonth()
      setDaysInMonth([...Array(days).keys()].map(d => d + 1))

      if (day !== "??" && parseInt(day) > days) {
        setDay("??")
      }
    }
  }, [month, year])

  useEffect(() => {
    if (day !== "??" && month === "??" && year === "??") {
      setError("Please select at least a month and a year.")
      setAnswer("")
      setDate(null)
      return
    }
    if (day !== "??" && month === "??" && year !== "??") {
      setError("Please select a month.")
      setAnswer("")
      setDate(null)
      return
    }
    setError("")

    const selectedDate = {
      day: day !== "??" ? day : "01",
      month: month !== "??" ? month : "01",
      year: year !== "??" ? year : currentYear
    }

    const dateObj = dayjs(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`)

    if (day === "??" && month === "??" && year !== "??") {
      setAnswer(`in ${year}`)
      setDate(dateObj.startOf("year"))
    } else if (day === "??" && month !== "??" && year === "??") {
      setAnswer(`in ${dateObj.format("MMMM YYYY")}`)
      setDate(dateObj.startOf("month"))
      setYear(currentYear.toString())
    } else if (day === "??" && month !== "??" && year !== "??") {
      setAnswer(`in ${dateObj.format("MMMM YYYY")}`)
      setDate(dateObj.startOf("month"))
    } else if (day !== "??" && month !== "??" && year !== "??") {
      setAnswer(`on ${dateObj.format("MMMM D, YYYY")}`)
      setDate(dateObj)
    } else if (day !== "??" && month !== "??" && year === "??") {
      setAnswer(`on ${dateObj.format("MMMM D, YYYY")}`)
      setDate(dateObj)
      setYear(currentYear.toString())
    } else if (day === "??" && month === "??" && year === "??") {
      setAnswer("")
      setDate(undefined)
    }
  }, [day, month, year, setAnswer, setDate, currentYear])

  const handleDayChange = (e: any) => setDay(e.target.value)
  const handleMonthChange = (e: any) => setMonth(e.target.value)
  const handleYearChange = (e: any) => setYear(e.target.value)

  const renderPeopleQuestionInput = (params: any) => {
    // Separates the string into id and complete name
    const [first, ...rest] = params?.inputProps?.value?.split(" ")
    const result = rest?.join(" ") || first
    // set only name or complete value if failed
    const contactName = result || params?.inputProps?.value
    params.inputProps.value = contactName
    return (
      <TextField
        {...params}
        placeholder={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
        inputProps={{
          ...params.inputProps,
          autoComplete: "new-password" // disable autocomplete and autofill
        }}
        InputLabelProps={{ shrink: focused || selectedContact, style: { color: "gray" } }}
        onFocus={() => {
          return onFocus()
        }}
      />
    )
  }
  const renderQuestionInput = (type: string) => {
    switch (type) {
      case QuestionType.open:
      case QuestionType.openFIDB:
      case QuestionType.button:
      case QuestionType.checkbox:
      case QuestionType.pickerYears:
        return (
          <TextField
            key={question.id}
            inputRef={inputRef}
            focused={focused}
            onFocus={() => {
              if (type === QuestionType.pickerYears && !date) {
                setDate(dayjs())
                setAnswer(
                  dayjs().toDate().toLocaleDateString("en-US", {
                    year: "numeric"
                  })
                )
              }

              return onFocus()
            }}
            onBlur={onBlur}
            InputLabelProps={{ shrink: focused || answer?.length > 0, style: { color: "gray" } }}
            id={question.id}
            label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
            variant="outlined"
            style={{ flex: 1, width: "100%" }}
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            autoComplete="off"
            // Keep this line (onKeyDown) or the keyboardListener won't work
            onKeyDown={async event => {}}
            InputProps={{
              readOnly: type !== QuestionType.open && type !== QuestionType.openFIDB,
              endAdornment: loading ? <ForgeLoading loading loadingType="small" style={{ height: "44px" }} /> : <Edit fontSize="small" />
            }}
          />
        )
      case QuestionType.picker:
        return (
          <MDBox className={classes.formTile}>
            <Autocomplete
              key={question.id}
              ref={inputRef}
              onFocus={onFocus}
              onBlur={onBlur}
              id={question.id}
              sx={{ width: loading ? "24rem" : "26.4rem" }}
              style={{ flex: 1 }}
              options={possibleAnswers ?? []}
              autoHighlight
              getOptionLabel={(option: string) => option}
              value={answer}
              onChange={(_: any, newValue: string) => {
                if (!newValue) return setAnswer("")
                return setAnswer(newValue)
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  {option}
                </Box>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.root}
                  label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
                  InputLabelProps={{ shrink: focused || answer?.length > 0, style: { color: "gray" } }}
                  inputProps={{
                    ...params.inputProps,
                    value: answer,
                    autoComplete: "new-password" // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <ForgeLoading loading={loading} loadingType="small" style={{ paddingBottom: "8px" }} />
          </MDBox>
        )
      case QuestionType.datePicker:
        return (
          <MDBox className={classes.formTile}>
            <DatePicker
              key={question.id}
              inputRef={inputRef}
              onOpen={onFocus}
              onChange={value => {
                if (value) {
                  setDate(value)
                  setAnswer(
                    "on " +
                      value.toDate().toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric"
                      })
                  )
                }
              }}
              sx={{ width: loading ? "24rem" : "26.4rem" }}
              label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
              format="MM/DD/YY"
              value={date}
            />
            <ForgeLoading loading={loading} loadingType="small" style={{ paddingBottom: "4px" }} />
          </MDBox>
        )
      case QuestionType.optionalDatePicker:
        return (
          <>
            {error && (
              <MDTypography variant="body2" color="error" textAlign="space-between" style={{ whiteSpace: "pre-line" }}>
                {error}
              </MDTypography>
            )}
            {!focused && (
              <TextField
                key={question.id}
                id={question.id}
                label={question.renderQuestion(contact?.questionsVars ?? {}, contact)}
                variant="outlined"
                style={{ flex: 1, width: "100%" }}
                value={answer}
                onChange={e => setAnswer(e.target.value)}
                autoComplete="off"
                onFocus={onFocus}
                InputLabelProps={{ shrink: focused || answer?.length > 0, style: { color: "gray" } }}
                InputProps={{
                  readOnly: true,
                  endAdornment: loading ? <ForgeLoading loading loadingType="small" style={{ height: "44px" }} /> : <Edit fontSize="small" />
                }}
              />
            )}
            {focused && (
              <Grid container spacing={{ lg: 0, md: 0, sm: 0, xs: 2 }} justifyContent="space-between" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid item xs={12} sm={4} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                  <TextField
                    select
                    label="Day"
                    value={day}
                    onChange={handleDayChange}
                    inputRef={inputRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    fullWidth
                    InputProps={{ style: { height: 44 } }}
                  >
                    <MenuItem value="??">??</MenuItem>
                    {daysInMonth.map(d => (
                      <MenuItem key={d} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Month"
                    value={month}
                    onChange={handleMonthChange}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    onFocus={onFocus}
                    fullWidth
                    InputProps={{ style: { height: 44 } }}
                  >
                    <MenuItem value="??">??</MenuItem>
                    {selectData.birthDate.map((m, index) => (
                      <MenuItem key={m} value={index + 1}>
                        {m}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Year"
                    value={year}
                    onChange={handleYearChange}
                    onBlur={onBlur}
                    inputRef={inputRef}
                    onFocus={onFocus}
                    fullWidth
                    InputProps={{ style: { height: 44 } }}
                  >
                    <MenuItem value="??">??</MenuItem>
                    {[...Array(currentYear - (currentYear - 100)).keys()].map(y => (
                      <MenuItem key={y} value={currentYear - y}>
                        {currentYear - y}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <ForgeLoading loading={loading} loadingType="small" style={{ paddingTop: "16px" }} />
              </Grid>
            )}
          </>
        )
      case QuestionType.people:
        return (
          <MDBox className={classes.formTile}>
            <Autocomplete
              id={question.id}
              sx={{ width: loading ? "24rem" : "26.4rem" }}
              style={{ flex: 1 }}
              options={contacts}
              value={selectedContact}
              onChange={(_: any, newValue: any) => setSelectedContact(newValue)}
              autoHighlight
              getOptionLabel={option => (option?.ref?.id ? `${option?.ref?.id} ${option?.name}` : "")}
              filterOptions={filterOptions}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.ref?.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                  <MDBox mr={2}>
                    <ForgeContactAvatar contact={option} enableScoreBadge={false} enableImageClick={false} />
                  </MDBox>
                  {option.name}
                </Box>
              )}
              renderInput={renderPeopleQuestionInput}
            />
            <ForgeLoading loading={loading} loadingType="small" style={{ paddingBottom: "8px" }} />
          </MDBox>
        )
    }
  }

  const saveAndClearButtons = ({ clearText = "Clear", question }: { clearText?: string; question: any }) => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: 6 }}>
        <MDButton
          variant="gradient"
          color="dark"
          size="small"
          type="submit"
          disabled={loading}
          onClick={handleClear}
          style={{ textTransform: "none" }}
        >
          {clearText}
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          type="submit"
          disabled={loading}
          onClick={async () => {
            await handleSave()
          }}
          onMouseDown={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{ textTransform: "none" }}
        >
          Save
        </MDButton>
      </Stack>
    )
  }

  const renderQuestionButtons = (question: any) => {
    switch (question.type) {
      case QuestionType.open:
      case QuestionType.openFIDB:
      case QuestionType.picker:
      case QuestionType.datePicker:
      case QuestionType.optionalDatePicker:
      case QuestionType.people:
        return saveAndClearButtons({
          clearText: question.type === QuestionType.people ? "Nobody" : "Clear",
          question
        })
      case QuestionType.pickerYears:
        return (
          <Stack
            direction="column"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: 4 }}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <YearCalendar
              sx={{ width: "100%" }}
              className={classes.root}
              maxDate={dayjs()}
              onChange={value => {
                setDate(value)
                setAnswer(
                  value.toDate().toLocaleDateString("en-US", {
                    year: "numeric"
                  })
                )
              }}
            />
            {saveAndClearButtons({ question })}
          </Stack>
        )
      case QuestionType.button:
        return (
          <Stack direction="row" alignItems="center" spacing={1} style={{ marginBottom: 4 }}>
            {possibleAnswers?.map((possibleAnswer: string) => (
              <MDButton
                id={possibleAnswer}
                variant="gradient"
                color="info"
                size="small"
                disabled={loading}
                onClick={async e => {
                  await handleSave(possibleAnswer)
                  setDate(null)
                  clearInput()
                }}
                onMouseDown={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ textTransform: "none" }}
              >
                {possibleAnswer}
              </MDButton>
            ))}
          </Stack>
        )
      case QuestionType.checkbox:
        return (
          <Stack
            direction="column"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: 4 }}
            onMouseDown={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormGroup>
                {possibleAnswers?.map((possibleAnswer: string, index: number) => (
                  <FormControlLabel
                    control={<Checkbox checked={selectedCheckbox[index]} onChange={() => updateCheckboxValue(index, !selectedCheckbox[index])} />}
                    label={possibleAnswer}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {saveAndClearButtons({ question })}
          </Stack>
        )
    }
  }

  return (
    <Stack width="100%" mb={2} direction="column" alignItems="center" spacing={1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {renderQuestionInput(question.type)}
        {(focused || answer) && renderQuestionButtons(question)}
      </LocalizationProvider>
    </Stack>
  )
}

export default QuestionField
