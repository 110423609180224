import { useContext, useEffect, useRef, useState } from "react"

// MUI
import { Box, Card, Divider, IconButton, Stack, TextField } from "@mui/material"
import { Delete, Done } from "@mui/icons-material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

// Services
import MDButton from "components/MDButton"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"

// Images
import { capitalize, debounce } from "lodash"
import dayjs from "dayjs"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { capitalizeAllWords } from "forge/core/utilities"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import { SearchTerm } from "forge/knowledge/schemas/search-term"
import { AuthContext } from "context"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { DocumentReference } from "firebase/firestore"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

function KnowledgeEdit({
  onEditCanceled,
  handleCloseDrawer,
  knowledge
}: {
  onEditCanceled: () => void
  handleCloseDrawer: () => void
  knowledge: any
}) {
  // Context
  const { userId } = useContext(AuthContext)
  const { getMember } = useContext(MembersContext)
  const { analyzeKnowledge, updateKnowledge, deleteKnowledge } = useContext(KnowledgeContext)

  // State
  const [isAuthoredByUser, setIsAuthoredByUser] = useState<boolean>(false)
  const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>()

  const initialAnalysis = useRef(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [editedKnowledge, setEditedKnowledge] = useState<any>(knowledge)
  const [text, setText] = useState<string>(knowledge?.answer?.replace(/{|}|\[|\]|\(|\)/g, ""))
  const [searchTerms, setSearchTerms] = useState<SearchTerm[]>([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<SearchTerm[]>([])
  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const handleOpenDialogDelete = () => setOpenDialogDelete(true)

  useEffect(() => {
    if (knowledge?.isOrganizationKnowledge && knowledge?.createdBy && knowledge.createdBy instanceof DocumentReference) {
      setMemberCreator(getMember(knowledge.createdBy.id))
    }
  }, [knowledge])

  useEffect(() => {
    if (knowledge?.isOrganizationKnowledge) {
      setIsAuthoredByUser(true)
      return
    }

    setIsAuthoredByUser(creatorMember && creatorMember.ref.id === userId)
  }, [creatorMember, userId])

  useEffect(() => {
    if (editedKnowledge) {
      setSearchTerms(editedKnowledge.options?.map((e: any) => SearchTerm.fromMap(e)))
      setSelectedSearchTerms(editedKnowledge.optionsSelected?.map((e: any) => SearchTerm.fromMap(e)))

      if (initialAnalysis.current) {
        initialAnalysis.current = false
        reAnalyzeKnowledge()
      }
      // console.log(editedKnowledge);
    }
  }, [editedKnowledge])

  useEffect(() => {
    setEditedKnowledge((prevValue: any) => {
      prevValue.answer = text
      return prevValue
    })

    // Call the debounced effect after 500ms
    debouncedEffect.current(text)
  }, [text])

  useEffect(() => {
    // Cancel the previous debounced effect
    return () => {
      debouncedEffect.current.cancel()
    }
  }, [])
  const debouncedEffect = useRef(debounce((newAnswer?: string) => reAnalyzeKnowledge(newAnswer), 800))
  const reAnalyzeKnowledge = async (newAnswer?: string) => {
    setLoading(true)

    if (newAnswer) {
      knowledge.answer = newAnswer
    }

    let newKnowledgeAnalysis = await analyzeKnowledge(knowledge, false)
    if (newKnowledgeAnalysis) {
      setEditedKnowledge(newKnowledgeAnalysis)
    }
    setLoading(false)
  }

  const onEditSearchTermSelected = (searchTerm: SearchTerm) => {
    // Check if the searchTerm exists in the array
    const selectedIndex = selectedSearchTerms.findIndex(item => item.idbID === searchTerm.idbID)

    if (selectedIndex !== -1) {
      // If the object exists, remove it from the array
      setSelectedSearchTerms(prevArray => prevArray.filter(item => item.idbID !== searchTerm.idbID))
    } else {
      // If the object doesn't exist, add it to the array
      setSelectedSearchTerms(prevArray => [...prevArray, searchTerm])
    }
  }

  const onDoneTapped = async () => {
    setLoading(false)
    setSaving(true)
    let result = await updateKnowledge(knowledge, text, selectedSearchTerms)
    if (result) {
      handleCloseDrawer()
    }
    setSaving(false)
  }

  const onDeleteTapped = async () => {
    handleOpenDialogDelete()
  }

  const handleCloseDialogDelete = async (result: boolean) => {
    setOpenDialogDelete(false)
    if (result) {
      setLoading(false)
      setSaving(true)
      let result = await deleteKnowledge(knowledge)
      if (result) {
        handleCloseDrawer()
      }
      setSaving(false)
    }
  }

  const subjectsOptions = editedKnowledge?.subjects?.map((subject: any) => {
    return (
      <Card
        style={{
          marginBottom: 8,
          background: true ? "#ffa5004f" : "white",
          border: true ? "2px solid orange" : "2px solid lightgray",
          cursor: "pointer"
        }}
        // onClick={() => onEditSearchTermSelected(e)}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" style={{ marginTop: 12, marginBottom: 12 }}>
          {true && <MDBox flex={2} />}
          <MDBox>
            <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "orange" }}>
              {capitalize(subject)}
            </MDTypography>
          </MDBox>
          {true && <MDBox flex={1.75} />}
          {true && <Done fontSize="medium" style={{ color: "orange", marginRight: 16 }} />}
        </Stack>
      </Card>
    )
  })

  const milestoneOption = (milestone: any) => (
    <Card
      style={{
        marginBottom: 8,
        background: true ? "#0080004f" : "white",
        border: true ? "2px solid green" : "2px solid lightgray",
        cursor: "pointer"
      }}
      // onClick={() => onEditSearchTermSelected(e)}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" style={{ marginTop: 12, marginBottom: 12 }}>
        {true && <MDBox flex={2} />}
        <MDBox>
          <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "green" }}>
            {capitalizeAllWords(milestone.subject)}, {capitalize(milestone.event)}
          </MDTypography>
          <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: true ? "white" : "black" }}>
            ,{" "}
            {milestone.year != null && milestone.month != null && milestone.day != null
              ? dayjs(new Date(milestone.year, milestone.month - 1, milestone.day)).format("MMM DD, YYYY")
              : dayjs(milestone.startDate?.toDate()).format("MMM DD")}
          </MDTypography>
        </MDBox>
        {true && <MDBox flex={1.75} />}
        {true && <Done fontSize="medium" style={{ color: "green", marginRight: 16 }} />}
      </Stack>
    </Card>
  )

  const searchTermOptions = (searchTerms ?? []).map((e: SearchTerm) => {
    let isSelected = selectedSearchTerms.some((st: SearchTerm) => st.idbID === e.idbID)
    return (
      <Card
        style={{
          marginBottom: 8,
          background: isSelected ? "#6495ed4f" : "white",
          border: isSelected ? "2px solid cornflowerblue" : "2px solid lightgray",
          cursor: "pointer"
        }}
        onClick={() => onEditSearchTermSelected(e)}
      >
        <Stack direction="row" alignItems="center" justifyContent="center" style={{ marginTop: 12, marginBottom: 12 }}>
          {isSelected && <MDBox flex={2} />}
          <MDBox>
            <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "cornflowerblue" }}>
              {e.searchTerm}
            </MDTypography>
            <MDTypography
              display="inline"
              variant="body2"
              fontWeight={isSelected ? "bold" : "normal"}
              style={{ color: isSelected ? "white" : "black" }}
            >
              , the {e.category}
            </MDTypography>
          </MDBox>
          {isSelected && <MDBox flex={1.75} />}
          {isSelected && <Done fontSize="medium" style={{ color: "cornflowerblue", marginRight: 16 }} />}
        </Stack>
      </Card>
    )
  })

  return (
    <MDBox>
      <ForgeLoading loading={saving} customHeight="45vh" customTop="14vh" />
      <ConfirmationDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        title="Are you sure you want to delete this insight?"
        description="This is a permanent action."
        confirmText="Delete"
        confirmColor="error"
      />
      <MDBox mx={3} mt={3} mb={loading ? 0 : 3}>
        <TextField
          id="title"
          variant="outlined"
          style={{ flex: 1, width: "95%" }}
          multiline
          maxRows={5}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <Can I="edit" a="knowledge" passThrough>
          {allowed => (
            <DisabledTooltip allowed={allowed && isAuthoredByUser} backgroundColor="grey">
              <IconButton onClick={allowed && isAuthoredByUser ? onDeleteTapped : undefined}>
                <Delete color={!allowed && isAuthoredByUser ? "disabled" : undefined} />
              </IconButton>
            </DisabledTooltip>
          )}
        </Can>
        <Divider />
        {subjectsOptions}
        {editedKnowledge?.milestone && milestoneOption(editedKnowledge.milestone)}
        {searchTermOptions}

        {loading && <ForgeLoading loading={loading} loadingType="inline" style={{ margin: "10px auto", height: "128px" }} />}
      </MDBox>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 1.5,
          pb: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          justifyContent: "space-between"
        }}
      >
        <MDButton variant="text" color={"dark"} onClick={onEditCanceled}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" color="info" onClick={onDoneTapped}>
          Done
        </MDButton>
      </Box>
    </MDBox>
  )
}

// Setting default values for the props of KnowledgeEdit
KnowledgeEdit.defaultProps = {
  onContactSelected: null
}

export default KnowledgeEdit
