import React, { useContext, useEffect, useState } from "react"
import { Box, Card, Divider, Drawer, FormControl, OutlinedInput, useMediaQuery } from "@mui/material"
import { PersonAdd, Search } from "@mui/icons-material"
import theme from "assets/theme"

import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import ExternalContactCard from "../ExternalContactCard"

import { AuthContext } from "context"
import { ContactContext } from "../../services/ContactContext"
import { Crm, CrmExtended } from "types/pipeline/crm"
import ContactsApi from "../../services/api"

interface ExternalContactsDrawerProps {
  openDrawer: boolean
  handleCloseDrawer: () => void
  notifyUpdate: () => void
  contact: any
}

const ExternalContactsDrawer = ({ openDrawer, handleCloseDrawer, contact, notifyUpdate }: ExternalContactsDrawerProps) => {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { crm, searchCrmContact, createCrmContact } = useContext(ContactContext)

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false)
  // Search
  const [possibleCrmContacts, setPossibleCrmContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [selectedContact, setSelectedContact] = useState<any>()
  const [searchQuery, setSearchQuery] = useState("")

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  useEffect(() => {
    ;(async () => {
      setLoadingContacts(true)
      setPossibleCrmContacts(await searchCrmContact())
      setLoadingContacts(false)
    })()
  }, [contact])

  useEffect(() => {
    setFilteredContacts(filterData(searchQuery, possibleCrmContacts).slice(0, 100))
  }, [searchQuery, possibleCrmContacts])

  const filterData = (query: string, data: any) => {
    if (!query) return data
    return data.filter((contact: any) => contact?.name?.toLowerCase().includes(query.toLowerCase()))
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!selectedContact) return
    setLoading(true)

    try {
      await contactsApi.updateContact({
        ref: contact?.ref,
        crmId: selectedContact?.crmId
      })
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
    handleCloseDrawer()
  }

  const onContactTapped = (contactTapped: any) => {
    if (selectedContact?.crmId === contactTapped?.crmId) {
      // Should unselect the contact
      setSelectedContact(undefined)
    } else setSelectedContact(contactTapped)
  }

  const onCreateCrmContact = async () => {
    createCrmContact(contact)
    handleCloseDrawer()
    notifyUpdate()
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            height: "auto",
            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
          }
        }}
      >
        <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
          <ForgeLoading loading={loading} customHeight="100%" />

          {/* Header */}
          <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3} style={{ background: "#F7F8FB" }}>
            <MDBox>
              <MDTypography variant="h5">External Contact</MDTypography>
            </MDBox>
            <MDButton variant="gradient" color="info" type="submit">
              Update
            </MDButton>
          </MDBox>
          <MDBox pt={1} style={{ background: "#F7F8FB" }} />
          <Divider style={{ background: "#F7F8FB", margin: 0, opacity: 1 }} />
          <MDBox pt={2} style={{ background: "#F7F8FB" }} />

          {/* Body */}
          <div style={{ background: "#F7F8FB", marginBottom: 5 }}>
            <MDBox mx={3}>
              <OutlinedInput
                id="search-bar"
                className="text"
                onInput={e => {
                  setSearchQuery((e.target as HTMLInputElement).value)
                }}
                placeholder="Search relationships"
                fullWidth={true}
                endAdornment={<Search />}
              />

              <MDBox mt={3} />
              <AddExternalContactCard crm={crm} onClick={() => onCreateCrmContact()} />

              <MDBox mt={3} />
              <ForgeLoading loading={loadingContacts} loadingType="medium" style={{ marginLeft: "19.5vh" }} />
              {filteredContacts?.map((contact: any) => {
                return (
                  <ExternalContactCard
                    key={contact?.crmId}
                    onClick={() => onContactTapped(contact)}
                    isSelected={selectedContact?.crmId === contact?.crmId}
                    contact={contact}
                    crm={crm}
                  />
                )
              })}
              {!loadingContacts && possibleCrmContacts.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
                  <MDTypography variant="body2" color="text">
                    No contact that match the search were found
                  </MDTypography>
                </Box>
              )}
            </MDBox>
          </div>
        </FormControl>
      </Drawer>
    </>
  )
}

const AddExternalContactCard = ({
  crm,
  onClick,
  maxOneLine = false,
  width = "100%"
}: {
  crm?: Crm
  onClick?: any
  maxOneLine?: boolean
  width?: string
}) => {
  return (
    <Card
      variant="outlined"
      style={{
        boxShadow: "none",
        border: "1.5px solid black",
        marginBottom: "8px",
        cursor: onClick ? "pointer" : "auto",
        width: width,
        background: "white"
      }}
      onClick={onClick}
    >
      <MDBox display="flex" alignItems="center" m={1}>
        <MDBox mx={1} my={1}>
          <PersonAdd style={{ marginTop: 1 }} />
        </MDBox>
        <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
          <MDBox mt={0.5} />
          <MDTypography
            variant="h6"
            lineHeight={1}
            color={"black"}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: maxOneLine ? 1 : null,
              textOverflow: "ellipsis"
            }}
          >
            Create a new contact in {CrmExtended.getName(crm)}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ExternalContactsDrawer
