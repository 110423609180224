import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { UserProfileData } from "types/user/user-profile-data"
import { CalendarEvent } from "types/calendar/calendar-event"
import { ForgeCalendar } from "types/calendar/calendar"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { User } from "firebase/auth"
import { addDays, startOfDay, subtractDays } from "forge/core/utilities"
import { ForgeEncryption } from "forge/core/services/encryption"
import HttpService from "forge/core/services/http.service"

dayjs.extend(utc)
dayjs.extend(timezone)

class EventsApi {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  createEvent = async (event: CalendarEvent, calendar: ForgeCalendar, activeMeet: boolean = false, timezone: string): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "calendars-createevent")
    if (!this.userProfileData) return

    let result
    try {
      const finalStartTime = dayjs(event.startDate.toDate()).tz(timezone, true)
      const finalEndTime = dayjs(event.endDate.toDate()).tz(timezone, true)

      result = await callable({
        event: event.toMap(),
        calendar: calendar.toMap(),
        startTime: finalStartTime.toISOString(),
        endTime: finalEndTime.toISOString(),
        timeZone: timezone,
        activeMeet: activeMeet,
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? []
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  fetchEvents = async (googleCalendars: ForgeCalendar[], microsoftCalendars: ForgeCalendar[], startDate?: Date, endDate?: Date): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "calendars-getevents")
    if (!this.userProfileData) return

    // Dates
    const now = new Date()
    const start = startDate ? startOfDay(startDate) : subtractDays(startOfDay(now), 28)
    const end = endDate ? endDate : addDays(start, 42)

    try {
      await callable({
        timeMin: start.toISOString(),
        timeMax: end.toISOString(),
        addContacts: true,
        googleCalendars: googleCalendars.map(e => e.toMap()),
        microsoftCalendars: microsoftCalendars.map(e => e.toMap()),
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? []
      })
    } catch (error) {
      console.error(error)
    }
  }

  updateHandler = async (
    event: CalendarEvent,
    calendar: ForgeCalendar,
    activeMeet: boolean = false,
    timezone: string,
    originalCalendar: ForgeCalendar,
    isOriginalRecurrent: boolean = false
  ) => {
    if (calendar.id !== originalCalendar.id) {
      // Always remove recurrence if event is changing from calendar
      await this.deleteEvent(event, originalCalendar, isOriginalRecurrent)
      return await this.createEvent(event, calendar, activeMeet, timezone)
    } else return await this.updateEvent(event, calendar, activeMeet, timezone)
  }

  updateEvent = async (event: CalendarEvent, calendar: ForgeCalendar, activeMeet: boolean = false, timezone: string): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "calendars-updateevent")
    if (!this.userProfileData) return

    let result
    try {
      const finalStartTime = dayjs(event.startDate.toDate()).tz(timezone, true)
      const finalEndTime = dayjs(event.endDate.toDate()).tz(timezone, true)

      result = await callable({
        event: event.toMap(),
        calendar: calendar.toMap(),
        startTime: finalStartTime.toISOString(),
        endTime: finalEndTime.toISOString(),
        timeZone: timezone,
        activeMeet: activeMeet,
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? []
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  updateEventInvite = async (event: CalendarEvent, calendar: ForgeCalendar, affectAllRecurrentEvents?: boolean): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "calendars-updateeventinvite")
    if (!this.userProfileData) return

    let result
    try {
      result = await callable({
        event: event.toMap(),
        calendar: calendar.toMap(),
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? [],
        updateRecurrence: affectAllRecurrentEvents,
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  deleteEvent = async (event: CalendarEvent, calendar: ForgeCalendar, deleteRecurrence = false): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "calendars-deleteevent")
    if (!this.userProfileData) return

    let result
    try {
      result = await callable({
        event: event.toMap(),
        calendar: calendar.toMap(),
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? [],
        deleteRecurrence
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  getContactEvents = async (contact: any): Promise<any> => {
    if (contact?.ref) {
      const endpoint = `events/contact/${contact.ref.id}`
      return await HttpService.get(endpoint, { refPath: contact.ref.path })
    }
    return []
  }
}

export default EventsApi
