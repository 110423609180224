import { useRef, useState } from "react"
import theme from "assets/theme"

// MUI
import { Divider, Drawer, Icon, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

// Services
import SelectContact from "forge/core/components/SelectContact"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"

// Images

function SelectContactDrawer({
  openDrawer,
  handleCloseDrawer,
  keepSelectedContact = false,
  recommendedIds = [],
  onContactSelected,
}: {
  openDrawer: boolean
  handleCloseDrawer: () => void
  keepSelectedContact?: boolean
  recommendedIds?: string[]
  onContactSelected?: (contact: any) => void
}) {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const drawerRef = useRef<HTMLDivElement | null>(null)

  // State
  const [selectedContact, setSelectedContact] = useState(null)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true)

  const handleClose = () => {

    if (!keepSelectedContact) {
      setSelectedContact(undefined)
    }

    return handleCloseDrawer()
  }

  const handleSelectContact = (newContact: any) => {
    setSelectedContact(newContact)
    handleOpenConfirmationDialog()
  }

  const handleCloseConfirmationDialog = (result: any) => {
    setOpenConfirmationDialog(false)
    handleCloseDrawer()
    if(result) {
      onContactSelected(selectedContact)
      setSelectedContact(null)
    }
  }

  return (
    <Drawer
      ref={drawerRef}
      anchor="right"
      open={openDrawer}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      <ConfirmationDialog
        openDialog={openConfirmationDialog}
        handleCloseDialog={handleCloseConfirmationDialog}
        title="Adding new email to contact"
        description={`Are you sure you want to add the new email to ${selectedContact?.name}`}
        denyVariant="outlined"
      />
      
      {/* Header */}
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MDBox>
          <MDTypography variant="h5">
            {`To which contact do you want to add the new email?`}
          </MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)"
          })}
          onClick={handleClose}
        >
          close
        </Icon>
      </MDBox>
      <Divider />

      {/* Body */}
      <SelectContact
        onContactSelected={handleSelectContact}
        drawerRef={drawerRef}
        filteredIds={[]}
        recommendedIds={recommendedIds}
      />
    </Drawer>
  )
}

SelectContactDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default SelectContactDrawer
