import { Close, Done, EmailOutlined, QuestionMark, Search, PersonAddAlt1 } from "@mui/icons-material"
import { DocumentReference } from "firebase/firestore"
import { documentReferenceFromMap } from "forge/core/utils/schema-parsing"

export enum AttendeeType {
  normal = "AttendeeType.normal",
  research = "AttendeeType.research"
}

export enum InvitationStatus {
  none = "InvitationStatus.none",
  accepted = "InvitationStatus.accepted",
  rejected = "InvitationStatus.rejected",
  maybe = "InvitationStatus.maybe"
}

export const AttendeeTypeExtended = {
  ...AttendeeType,
  icon(type: AttendeeType): JSX.Element {
    switch (type) {
      case AttendeeType.research:
        return <Search />
      case AttendeeType.normal:
      default:
        return <EmailOutlined />
    }
  },
  color(type: AttendeeType): string {
    switch (type) {
      case AttendeeType.research:
        return "#3D79EE"
      case AttendeeType.normal:
      default:
        return "black"
    }
  }
}

export const InvitationStatusExtended = {
  ...InvitationStatus,
  icon(type: InvitationStatus): JSX.Element {
    switch (type) {
      case InvitationStatus.accepted:
        return <Done />
      case InvitationStatus.maybe:
        return <QuestionMark />
      case InvitationStatus.rejected:
        return <Close />
      default:
        return <EmailOutlined />
    }
  },
  color(type: InvitationStatus): string {
    switch (type) {
      case InvitationStatus.accepted:
        return "green"
      case InvitationStatus.maybe:
        return "orange"
      case InvitationStatus.rejected:
        return "red"
      default:
        return "gray"
    }
  }
}

export class ForgeAttendee {
  name?: string
  emailAddress?: string
  isOrganizer: boolean = false
  contact?: any
  type: AttendeeType
  status: InvitationStatus
  ref: DocumentReference

  constructor({
    name,
    emailAddress,
    isOrganizer = false,
    contact,
    type = AttendeeType.normal,
    status = InvitationStatus.none,
    ref
  }: {
    name?: string
    emailAddress?: string
    isOrganizer?: boolean
    contact?: any
    type?: AttendeeType
    status?: InvitationStatus
    ref?: DocumentReference
  }) {
    this.name = name
    this.emailAddress = emailAddress
    this.isOrganizer = isOrganizer
    this.contact = contact
    this.type = type
    this.status = status
    this.ref = ref
  }

  copyWith({
    name,
    emailAddress,
    isOrganizer,
    contact,
    type,
    status,
    ref
  }: {
    name?: string
    emailAddress?: string
    isOrganizer?: boolean
    contact?: any
    type?: AttendeeType
    status?: InvitationStatus
    ref?: DocumentReference
  }): ForgeAttendee {
    return new ForgeAttendee({
      name: name ?? this.name,
      emailAddress: emailAddress ?? this.emailAddress,
      isOrganizer: isOrganizer ?? this.isOrganizer,
      contact: contact ?? this.contact,
      type: type ?? this.type,
      status: status ?? this.status,
      ref: ref ?? this.ref
    })
  }

  toMap(): Record<string, any> {
    return {
      name: this.name,
      emailAddress: this.emailAddress,
      isOrganizer: this.isOrganizer,
      type: this.type,
      status: this.status
    }
  }

  static fromMap(map: Record<string, any>): ForgeAttendee {
    return new ForgeAttendee({
      name: map["name"],
      emailAddress: map["emailAddress"],
      isOrganizer: map["isOrganizer"] ?? false,
      type: map["type"] != null ? (map["type"] === "AttendeeType.research" ? AttendeeType.research : AttendeeType.normal) : AttendeeType.normal,
      ref: map["ref"] ? documentReferenceFromMap(map.ref) : null,
      status:
        map["status"] != null
          ? map["status"] === "InvitationStatus.accepted"
            ? InvitationStatus.accepted
            : map["status"] === "InvitationStatus.maybe"
              ? InvitationStatus.maybe
              : map["status"] === "InvitationStatus.rejected"
                ? InvitationStatus.rejected
                : InvitationStatus.none
          : InvitationStatus.none
    })
  }

  get color(): string {
    return this.type === AttendeeType.research ? AttendeeTypeExtended.color(this.type) : InvitationStatusExtended.color(this.status)
  }

  get icon(): JSX.Element {
    return this.type === AttendeeType.research ? AttendeeTypeExtended.icon(this.type) : InvitationStatusExtended.icon(this.status)
  }
}
