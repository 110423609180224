import { collection, query, orderBy, Unsubscribe, QuerySnapshot, DocumentData } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { firestoreDebounce } from "forge/core/utils/firestore"
import { ForgeEncryption } from "forge/core/services/encryption"
import { Knowledge } from "../schemas/knowledge"

const identifyKnowledgeChanges = (previousKnowledge: Knowledge[], newKnowledgeArray: Knowledge[], orderChanges: string[]) => {
  let knowledgeArray = [...previousKnowledge]

  for (const newKnowledge of newKnowledgeArray) {
    const mainIndex = newKnowledgeArray.indexOf(newKnowledge)
    if (orderChanges[mainIndex] === "added") knowledgeArray.push(newKnowledge)
    else if (orderChanges[mainIndex] === "modified") {
      const knowledge = previousKnowledge.find(knowledge => knowledge.ref.id === newKnowledge.ref.id)
      previousKnowledge[previousKnowledge.indexOf(knowledge)] = newKnowledge
    } else if (orderChanges[mainIndex] === "removed") {
      knowledgeArray = knowledgeArray.filter(knowledge => knowledge.ref.id !== newKnowledge.ref.id)
    }
  }

  return knowledgeArray
}

class KnowledgeFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getPersonalKnowledgeLive = (contactId: string, onEvent: (knowledge: Knowledge[]) => void): Unsubscribe => {
    const collectionRef = query(collection(firestoreDb, `users/${this.user.uid}/contacts/${contactId}/knowledges`), orderBy("createdAt", "desc"))
    let knowledgeArray: Knowledge[] = []
    return firestoreDebounce(collectionRef, async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
      const changeOrder: string[] = []
      const knowledgePromises = snapshot.docChanges().map(docChange => {
        changeOrder.push(docChange.type)
        return Knowledge.fromMap(
          {
            ...docChange.doc.data(),
            isOrganizationKnowledge: false
          },
          this.encryptionService
        )
      })

      const decrypted = await Promise.allSettled(knowledgePromises)
      const knowledge: Knowledge[] = decrypted.filter((e: any) => e.status === "fulfilled").map((e: any) => e.value)

      knowledgeArray = identifyKnowledgeChanges(knowledgeArray, knowledge, changeOrder)
      onEvent(knowledgeArray)
    })
  }

  getOrganizationKnowledgeLive = (contactId: string, onEvent: (knowledge: Knowledge[]) => void): Unsubscribe => {
    if (this.userProfileData?.doesUserBelongsToAnOrganization) {
      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/contacts/${contactId}/knowledges`),
        orderBy("createdAt", "desc")
      )

      let knowledgeArray: Knowledge[] = []
      return firestoreDebounce(collectionRef, async (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
        const changeOrder: string[] = []
        const knowledgePromises = snapshot.docChanges().map(docChange => {
          changeOrder.push(docChange.type)
          return Knowledge.fromMap(
            {
              ...docChange.doc.data(),
              isOrganizationKnowledge: true
            },
            this.encryptionService
          )
        })

        const decrypted = await Promise.allSettled(knowledgePromises)
        const knowledge: Knowledge[] = decrypted.filter((e: any) => e.status === "fulfilled").map((e: any) => e.value)

        knowledgeArray = identifyKnowledgeChanges(knowledgeArray, knowledge, changeOrder)
        onEvent(knowledgeArray)
      })
    }

    return
  }
}

export default KnowledgeFirestore
