import { DocumentReference, Unsubscribe, collection, doc, getDoc, orderBy, query, where } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { Call } from "types/pipeline/call"
import { ForgeEncryption } from "forge/core/services/encryption"
import { firestoreDebounce } from "forge/core/utils/firestore"
import { subtractDays } from "forge/core/utilities"

const identifyCallChanges = (previousCall: Call[], newCallArray: Call[], orderChanges: string[]) => {
  let callArray = [...previousCall]

  for (const newCall of newCallArray) {
    const mainIndex = newCallArray.indexOf(newCall)
    if (orderChanges[mainIndex] === "added") callArray.push(newCall)
    else if (orderChanges[mainIndex] === "modified") {
      const call = previousCall.find(call => call.ref.id === newCall.ref.id)
      previousCall[previousCall.indexOf(call)] = newCall
    } else if (orderChanges[mainIndex] === "removed") {
      callArray = callArray.filter(call => call.ref.id !== newCall.ref.id)
    }
  }

  return callArray
}

class CallsFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getCall = async (callId: string, eventRef?: DocumentReference): Promise<Call | undefined> => {
    let docRef = doc(firestoreDb, `organizations/${this.userProfileData?.organization?.id}/calls/${callId}`)
    let callDoc = await getDoc(docRef)

    if (!callDoc.exists()) {
      docRef = doc(firestoreDb, `users/${this.user.uid}/calls/${callId}`)
      callDoc = await getDoc(docRef)

      if (!callDoc.exists()) {
        return
      }
    }

    return await Call.fromMap(callDoc.data(), this.encryptionService)
  }

  getPersonalCallsLive = (onEvent: (calls: Call[]) => void, opportunityId?: string, contactRef?: DocumentReference): Unsubscribe => {
    const whereCondition = opportunityId
      ? where("opportunityId", "==", opportunityId)
      : contactRef
        ? where("contacts", "array-contains", contactRef)
        : undefined

    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/calls`),
      whereCondition,
      where("date", ">=", subtractDays(new Date(), 180)),
      orderBy("date", "asc")
    )

    let callArray: Call[] = []
    return firestoreDebounce(collectionRef, async snapshot => {
      const changeOrder: string[] = []
      const callsPromises = snapshot.docChanges().map(docChange => {
        changeOrder.push(docChange.type)
        return Call.fromMap(
          {
            ...docChange.doc.data(),
            isOrganizationCall: true
          },
          this.encryptionService
        )
      })

      const decrypted = await Promise.allSettled(callsPromises)
      const calls: Call[] = decrypted.filter((e: any) => e.status === "fulfilled").map((e: any) => e.value)

      callArray = identifyCallChanges(callArray, calls, changeOrder)
      onEvent(callArray)
    })
  }

  getOrganizationCallsLive = (onEvent: (calls: Call[]) => void, opportunityId?: string, contactRef?: DocumentReference): Unsubscribe => {
    if (this.userProfileData?.doesUserBelongsToAnOrganization) {
      const whereCondition = opportunityId
        ? where("opportunityId", "==", opportunityId)
        : contactRef
          ? where("contacts", "array-contains", contactRef)
          : undefined

      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData?.organization?.id}/calls`),
        whereCondition,
        where("date", ">=", subtractDays(new Date(), 180)),
        orderBy("date", "desc")
      )

      let callArray: Call[] = []
      return firestoreDebounce(collectionRef, async snapshot => {
        const changeOrder: string[] = []
        const callsPromises = snapshot.docChanges().map(docChange => {
          changeOrder.push(docChange.type)
          return Call.fromMap(
            {
              ...docChange.doc.data(),
              isOrganizationCall: true
            },
            this.encryptionService
          )
        })

        const decrypted = await Promise.allSettled(callsPromises)
        const calls: Call[] = decrypted.filter((e: any) => e.status === "fulfilled").map((e: any) => e.value)

        callArray = identifyCallChanges(callArray, calls, changeOrder)
        onEvent(callArray)
      })
    }
  }
}

export default CallsFirestore
