import React from "react"
import { Card } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

import { Crm, CrmExtended } from "types/pipeline/crm"

const ExternalContactCard = ({
  contact,
  crm,
  onClick,
  isSelected = false,
  maxOneLine = false,
  width = "100%"
}: {
  contact: any
  crm?: Crm
  onClick?: any
  isSelected?: boolean
  maxOneLine?: boolean
  width?: string
}) => {
  return (
    <Card
      variant="outlined"
      style={{
        boxShadow: "none",
        border: "1.5px solid black",
        marginBottom: "8px",
        cursor: onClick ? "pointer" : "auto",
        width: width,
        background: isSelected ? "black" : "white"
      }}
      onClick={onClick}
    >
      <MDBox display="flex" alignItems="center" m={1}>
        <MDBox mx={1} my={1}>
          <img src={CrmExtended.getIcon(crm)} style={{ height: "30px" }} alt={CrmExtended.getName(crm)} />
        </MDBox>
        <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
          <MDBox mt={0.5} />
          <MDTypography
            variant="h6"
            lineHeight={1}
            color={isSelected ? "white" : "black"}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: maxOneLine ? 1 : null,
              textOverflow: "ellipsis"
            }}
          >
            {contact.name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ExternalContactCard
