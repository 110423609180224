import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import theme from "assets/theme"

// Context
import { AuthContext, ContactsContext } from "context"

// @mui material components
import { Drawer, Icon, IconButton, Stack, useMediaQuery } from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import Footer from "examples/Footer"
import Board from "@asseinfo/react-kanban"

// Components
import AddKnowledgeDrawer from "../../../knowledge/components/AddKnowledgeDrawer"

import ContactHeaderCard from "../components/ContactHeaderCard"
import ContactResearchCard from "../components/ContactResearchCard"
import ContactRecallCard from "../components/ContactRecallCard"
import KnowledgeDetail from "../../../knowledge/components/KnowledgeDetail"
import ContactNewsCard from "../components/ContactNewsCard"
import ContactCommitmentsCard from "../components/ContactCommitmentsCard"
import ContactMilestonesCard from "../components/ContactMilestonesCard"
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer"
import ContactListenCard from "../components/ContactListenCard"
import { DocumentReference, Timestamp } from "firebase/firestore"
import ContactsApi from "../services/api"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import ContactCallsCard from "../components/ContactCallsCard"
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer"
import ContextWrapper from "../ContextWrapper/ContextWrapper"
import CreateEventDrawer from "forge/calendar/components/CreateEventDrawer"
import "./index.css"
import MDTypography from "components/MDTypography"
import { createPortal } from "react-dom"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import MDSnackbar from "components/MDSnackbar"
import { ColorType, SnackbarProps } from "types/snackbar"
import MDButton from "components/MDButton"
import MergeContactsDrawer from "../components/MergeContactsDrawer"

const boards = {
  columns: [
    {
      id: "research",
      cards: [
        {
          id: "researchCard"
        }
      ]
    },
    {
      id: "listen",
      cards: [
        {
          id: "listenCard"
        }
      ]
    },
    ,
    {
      id: "recall",
      cards: [
        {
          id: "recallCard"
        }
      ]
    },
    {
      id: "commitments",
      cards: [
        {
          id: "commitmentsCard"
        }
      ]
    },
    {
      id: "calls",
      cards: [
        {
          id: "callsCard"
        }
      ]
    },
    {
      id: "news",
      cards: [
        {
          id: "newsCard"
        }
      ]
    },
    {
      id: "milestones",
      cards: [
        {
          id: "milestonesCard"
        }
      ]
    }
  ]
}

const ContactOverview = ({
  contactId,
  isDraftContact = false,
  addDraftContact = (contact: any) => {}
}: {
  contactId?: string
  isDraftContact?: boolean
  addDraftContact?: (contact: any) => void
}) => {
  const { id } = useParams()
  const { getCurrentUser } = useContext(AuthContext)
  const { contactsMap, getContact, getDraftContacts } = useContext(ContactsContext)

  // State
  const [contact, setContact] = useState<any>({})
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [isLive, setIsLive] = useState<boolean>(false)
  const [initialContacts, setInitialContacts] = useState<string[]>()
  const [scroll, setScroll] = useState<number>(0)
  const [isFirstRightClick, setIsFirstRightClick] = useState(true)
  const [openNameDialog, setOpenNameDialog] = useState<boolean>(false)
  const [mergingContact, setMergingContact] = useState<any>()
  const [showLeftButton, setShowLeftButton] = useState<any>(false)
  const [showRightButton, setShowRightButton] = useState<any>(true)

  // Services
  let { user, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  // Add Knowledge
  const [openKnowledgeDrawer, setOpenKnowledgeDrawer] = useState(false)
  const handleOpenDrawer = () => setOpenKnowledgeDrawer(true)
  const handleCloseDrawer = () => setOpenKnowledgeDrawer(false)

  // Add Commitment
  const [openCommitmentDrawer, setOpenCommitmentDrawer] = useState(false)
  const handleOpenCommitmentDrawer = () => setOpenCommitmentDrawer(true)
  const handleCloseCommitmentDrawer = () => setOpenCommitmentDrawer(false)

  // Add Call
  const [openCallDrawer, setOpenCallDrawer] = useState(false)
  const handleOpenCallDrawer = () => setOpenCallDrawer(true)
  const handleCloseCallDrawer = () => setOpenCallDrawer(false)

  // Add Meet
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)
  const handleOpenEventDrawer = () => setOpenCreateEventDrawer(true)
  const handleCloseEventDrawer = () => setOpenCreateEventDrawer(false)

  // Knowledge Detail
  const [knowledge, setKnowledge] = useState<any>()
  const [linkedInData, setLinkedInData] = useState<boolean>(false)
  const [openKnowledgeDetailDrawer, setOpenKnowledgeDetailDrawer] = useState(false)

  // Merge - for draft contacts
  const [openMergeDrawer, setOpenMergeDrawer] = useState(false)
  const handleOpenMergeDrawer = () => setOpenMergeDrawer(true)
  const handleCloseMergeDrawer = () => setOpenMergeDrawer(false)

  // Snackbar
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    open: false,
    color: "info",
    title: "",
    message: ""
  })

  const handleOpenDetailDrawer = (knowledge: any, linkedInData?: boolean) => {
    setKnowledge(knowledge)
    setLinkedInData(linkedInData)
    setOpenKnowledgeDetailDrawer(true)
  }
  const handleCloseDetailDrawer = () => setOpenKnowledgeDetailDrawer(false)
  // Reference to the board container
  const boardContainerRef = useRef(null)

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const getContactInfo = () => {
    let response
    if (!isDraftContact) response = getContact(contactId ?? id)
    else response = getDraftContacts([contactId])[0]
    setContact(response)

    return response
  }

  useEffect(() => {
    if (!id && !contactId) return

    getContactInfo()
  }, [contactsMap, isDraftContact])

  useEffect(() => {
    if (!id && !contactId) return

    setInitialContacts([contactId ?? id])
    const response = getContactInfo()

    if (
      !isDraftContact &&
      response &&
      response.relationshipDataTimeDecayUpdatedAt &&
      response.relationshipDataTimeDecayUpdatedAt instanceof Timestamp
    ) {
      // Get the current date
      const currentDate = new Date()

      // Subtract 30 days from the current date
      const comparisonDate = new Date()
      comparisonDate.setDate(currentDate.getDate() - 30)
      const relationshipDataTimeDecayUpdatedAt = response.relationshipDataTimeDecayUpdatedAt.toDate()

      if (relationshipDataTimeDecayUpdatedAt < comparisonDate) {
        contactsApi.updateRelationshipData(response)
      }
    }
  }, [contactId ?? id])

  useEffect(() => {
    if (!contact) return

    setIsLive(contact?.status === "live")
    setIsDraft(contact?.status === "draft")
    var firstName = contact.linkedInProfileData?.first_name?.trim()
    var lastName = contact.linkedInProfileData?.last_name?.trim()
    var differentFirstName = firstName != null && contact.firstName !== firstName
    var differentLastName = lastName != null && contact.lastName !== lastName

    if ((differentFirstName || differentLastName) && !(contact.linkedInProfileData?.differentNameFlag ?? false)) {
      setOpenNameDialog(true)
    }

    if (contact.status === "merging" && contact.mergingWith && contact.mergingWith instanceof DocumentReference) {
      setMergingContact(getContact(contact.mergingWith.id))
    }
  }, [contact])

  const renderCard = (id: string) => {
    switch (id) {
      case "researchCard":
        return (
          <ContactResearchCard
            contact={contact}
            handleOpenDetailDrawer={(knowledge: any, linkedInData?: any) => handleOpenDetailDrawer(knowledge, true)}
          />
        )
      case "listenCard":
        return <ContactListenCard contact={contact} />
      case "recallCard":
        return <ContactRecallCard contact={contact} handleOpenDrawer={handleOpenDrawer} handleOpenDetailDrawer={handleOpenDetailDrawer} />
      case "newsCard":
        return <ContactNewsCard handleOpenDrawer={handleOpenDrawer} />
      case "commitmentsCard":
        return <ContactCommitmentsCard contact={contact} handleOpenDrawer={handleOpenCommitmentDrawer} />
      case "milestonesCard":
        return <ContactMilestonesCard contact={contact} handleOpenDrawer={handleOpenDrawer} />
      case "callsCard":
        return <ContactCallsCard contact={contact} handleOpenDrawer={handleOpenCallDrawer} handleOpenEventDrawer={handleOpenEventDrawer} />
      default:
        return <></>
    }
  }

  const handleCloseDialogName = async (result: boolean) => {
    setOpenNameDialog(false)
    if (result) {
      var firstName = contact?.linkedInProfileData?.first_name ?? contact?.firstName ?? ""
      var lastName = contact?.linkedInProfileData?.last_name ?? contact?.lastName ?? ""
      const name = `${firstName ?? ""} ${lastName ?? ""}`.trim()

      await contactsApi.updateContact({
        ref: contact?.ref,
        firstName,
        lastName,
        name
      })
    } else {
      await contactsApi.updateContact({
        ref: contact?.ref,
        "linkedInProfileData.differentNameFlag": true
      })
    }
  }

  useEffect(() => {
    if (boardContainerRef.current) {
      boardContainerRef.current.scrollLeft = scroll
    }
  }, [scroll])

  const panelWidth = 494
  const handleScrollValue = (scrollOffset: number) => {
    const contactBoardHolder = document.getElementById("contactBoardHolder")
    if (!contactBoardHolder) return

    const computedStyle = window.getComputedStyle(contactBoardHolder)
    const elementWidth = parseFloat(computedStyle.width)
    const alignmentOffset = (elementWidth % panelWidth) + 24
    const maxScrollValue = panelWidth * 7 - elementWidth - 24

    let adjustedScrollOffset = scrollOffset

    if (scrollOffset >= 0) {
      if (isFirstRightClick) {
        adjustedScrollOffset -= alignmentOffset
        setIsFirstRightClick(false)
      }
    } else {
      if (!isFirstRightClick) {
        adjustedScrollOffset += alignmentOffset
        setIsFirstRightClick(true)
      }
    }

    const newScroll = scroll + adjustedScrollOffset

    checkButtonsVisibility(newScroll, maxScrollValue)

    setScroll(Math.max(0, Math.min(newScroll, maxScrollValue)))
  }

  const checkButtonsVisibility = (scroll: number, maxScrollValue: number) => {
    if (scroll <= 0) {
      setShowLeftButton(false)
    } else {
      setShowLeftButton(true)
    }

    if (scroll >= maxScrollValue) {
      setShowRightButton(false)
    } else {
      setShowRightButton(true)
    }
  }

  const scrollToLeft = () => handleScrollValue(-panelWidth)
  const scrollToRight = () => handleScrollValue(panelWidth)

  const handleSnackbar = (color: ColorType, title: string, message: string) => {
    setSnackbar({
      open: true,
      color,
      title,
      message
    })
  }

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }))
  }

  const renderSnackbar = createPortal(
    <MDSnackbar
      color={snackbar.color}
      icon="notifications"
      dateTime=""
      title={snackbar.title}
      content={snackbar.message}
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      close={handleCloseSnackbar}
      bgWhite
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: 1500
      }}
    />,
    document.body
  )

  const addContactClick = async () => {
    await addDraftContact(contact)
  }

  return (
    <ContextWrapper contact={contact}>
      <>
        {renderSnackbar}
        <ConfirmationDialog
          openDialog={openNameDialog}
          handleCloseDialog={handleCloseDialogName}
          title="Do you want to update this person's name?"
          description={
            <Fragment>
              LinkedIn has a different spelling.
              <br />
              {`First Name: ${contact?.linkedInProfileData?.first_name}`}
              <br />
              {`Last Name: ${contact?.linkedInProfileData?.last_name}`}
            </Fragment>
          }
          denyText="Ignore"
          confirmText="Update"
          denyVariant="outlined"
          confirmColor="info"
        />
        <MergeContactsDrawer
          contact={contact}
          openDrawer={openMergeDrawer}
          handleCloseDrawer={handleCloseMergeDrawer}
          onNotify={notification => {
            const snackbarNotification: SnackbarProps = {
              open: true,
              color: notification.color,
              title: notification.title,
              message: notification.content
            }

            setSnackbar(snackbarNotification)
          }}
          isDraftContact={isDraft}
        />
        <AddKnowledgeDrawer openDrawer={openKnowledgeDrawer} handleCloseDrawer={handleCloseDrawer} contact={contact} keepSelectedContact={true} />
        <Drawer
          anchor="right"
          open={openKnowledgeDetailDrawer}
          onClose={handleCloseDetailDrawer}
          PaperProps={{
            sx: {
              height: "auto",
              maxHeight: "96%",
              width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
            }
          }}
        >
          <KnowledgeDetail handleCloseDrawer={handleCloseDetailDrawer} knowledge={knowledge} displayKnowledge={!linkedInData} />
        </Drawer>
        <CreateCommitmentDrawer
          openDrawer={openCommitmentDrawer}
          handleCloseDrawer={handleCloseCommitmentDrawer}
          contact={contact}
          keepSelectedContact={true}
          showSnackbar={handleSnackbar}
        />
        <CreateCallDrawer openDrawer={openCallDrawer} handleCloseDrawer={handleCloseCallDrawer} contact={contact} showSnackbar={handleSnackbar} />
        <CreateEventDrawer
          openDrawer={openCreateEventDrawer}
          handleCloseDrawer={handleCloseEventDrawer}
          initialContacts={initialContacts}
          eventName={`${contact?.firstName} & ${userProfileData?.firstName}`}
        />

        <MDBox mb={3}>
          <ContactHeaderCard contact={contact} isContactProfilePage={contactId === null || contactId === undefined} />

          {isLive && showLeftButton && (
            <IconButton
              aria-label="Move left"
              style={{ background: "gray", opacity: "0.3", zIndex: 1, position: "fixed", top: "48vh" }}
              onClick={scrollToLeft}
            >
              <KeyboardArrowLeft style={{ color: "white" }} />
            </IconButton>
          )}

          {isLive && showRightButton && (
            <IconButton
              aria-label="Move right"
              style={{ background: "gray", opacity: "0.3", zIndex: 1, position: "fixed", top: "48vh", right: "4vh" }}
              onClick={scrollToRight}
            >
              <KeyboardArrowRight style={{ color: "white" }} />
            </IconButton>
          )}

          {isLive ? (
            <div className="contactBoardHolder" id="contactBoardHolder" style={{ overflowX: "auto" }} ref={boardContainerRef}>
              <Board
                initialBoard={boards}
                disableCardDrag={true}
                renderColumnHeader={() => <></>}
                renderCard={({ id, template }: any, { dragging }: any) => (
                  <MDBox
                    key={id}
                    dragging={dragging.toString() || undefined}
                    display="block"
                    width="calc(510px - 40px)"
                    color="text"
                    borderRadius="xl"
                    mt={2.55}
                    mr={3}
                    pb={1}
                    sx={{ height: "100%" }}
                  >
                    {renderCard(id)}
                  </MDBox>
                )}
              />
            </div>
          ) : contact?.status === "merging" ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <Stack direction="column" alignItems="center" style={{ marginTop: "24vh", marginBottom: "24vh" }}>
                <Icon fontSize="medium" color="inherit">
                  merge
                </Icon>
                <MDTypography variant="body2" color="textSecondary">
                  Merging with
                </MDTypography>
                {mergingContact && (
                  <MDTypography variant="body2" color="textSecondary">
                    {mergingContact.name}
                  </MDTypography>
                )}
                <ForgeLoading loading loadingType="medium" />
              </Stack>
            </MDBox>
          ) : isDraft ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <Stack direction="column" alignItems="center" style={{ marginTop: "22vh", marginBottom: "24vh" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                  style={{ textTransform: "none", height: "40px" }}
                  onClick={addContactClick}
                >
                  + Add {contact?.firstName} as a contact
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                  style={{ textTransform: "none", height: "40px", marginTop: 10 }}
                  onClick={handleOpenMergeDrawer}
                >
                  + Merge {contact?.firstName} with existing contact
                </MDButton>
              </Stack>
            </MDBox>
          ) : (
            <></>
          )}
        </MDBox>
        {!contactId && <Footer />}
      </>
    </ContextWrapper>
  )
}

export default ContactOverview
