import { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import theme from "assets/theme"

// @mui material components
import {
  Tooltip,
  Avatar,
  Box,
  Drawer,
  useMediaQuery,
  Card,
  Stack,
  IconButton,
  Icon,
  Typography,
  CircularProgress,
  CircularProgressProps
} from "@mui/material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import Footer from "examples/Footer"
import DataTable from "examples/Tables/DataTable"
import MDButton from "components/MDButton"

import CreateOpportunity from "./components/CreateOpportunity"
import { ApartmentRounded, Person, QuestionMark, Sync, Verified } from "@mui/icons-material"
import { OpportunitiesContext } from "./services/OpportunitiesContext"
import { ForgeOpportunity } from "types/pipeline/opportunity"
import { AuthContext, ContactsContext } from "context"
import { getColorWithGradient, getWarmthScore } from "types/contact/contact"
import { smartTime } from "forge/core/utilities"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { Pie } from "react-chartjs-2"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import ContactWarmthDialog from "forge/people/contacts/components/ContactWarmthDialog"
import { CrmExtended } from "types/pipeline/crm"
import { CrmContext } from "forge/settings/services/CrmContext"
import BillingDialog from "forge/settings/components/SettingsBilling/BillingDialog"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { UserPipelineConfig } from "types/user/user-pipeline-config"
import { Timestamp } from "firebase/firestore"

function OpportunitiesManagement(): JSX.Element {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { userProfileData } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)
  const { opportunities, showClosed, setShowClosed, getOpportunities } = useContext(OpportunitiesContext)
  const { pipelineConfig, organizationPipelineConfig } = useContext(CrmContext)

  // State
  const [openBillingDialog, setOpenBillingDialog] = useState(false)
  const [finalPipelineConfig, setFinalPipelineConfig] = useState<UserPipelineConfig>()
  const [openCreateOpportunityDrawer, setOpenCreateOpportunityDrawer] = useState(false)

  const handleOpenDrawer = () => {
    if (!organization?.hasAnActiveSubscription && !userProfileData.hasAnActiveSubscription) {
      setOpenBillingDialog(true)
      return
    }

    return setOpenCreateOpportunityDrawer(true)
  }
  const handleCloseDrawer = () => setOpenCreateOpportunityDrawer(false)

  useEffect(() => {
    if (pipelineConfig?.organizationId || userProfileData?.organization?.id) setFinalPipelineConfig(organizationPipelineConfig)
    else setFinalPipelineConfig(pipelineConfig)
  }, [pipelineConfig, organizationPipelineConfig])

  return (
    <DashboardLayout>
      <BillingDialog openDialog={openBillingDialog} handleCloseDialog={() => setOpenBillingDialog(false)} />
      <Drawer
        anchor="right"
        open={openCreateOpportunityDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
          }
        }}
      >
        <CreateOpportunity handleCloseDrawer={handleCloseDrawer} />
      </Drawer>
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
              <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <MDTypography variant="h5" fontWeight="medium">
                    Pipeline
                  </MDTypography>
                </Stack>
                {finalPipelineConfig?.isEnabled && finalPipelineConfig?.integration && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img
                      src={CrmExtended.getIcon(finalPipelineConfig.integration)}
                      style={{ height: "30px" }}
                      alt={CrmExtended.getName(finalPipelineConfig.integration)}
                    />
                    <MDTypography variant="body2" fontWeight="medium">
                      {CrmExtended.getName(finalPipelineConfig.integration)}
                    </MDTypography>
                    <Verified style={{ color: "green" }} />
                    {opportunities.length > 0 && (
                      <>
                        {finalPipelineConfig?.pipelineLoading ? (
                          <>
                            <MDTypography variant="body2" fontWeight="normal" fontSize="small">
                              Sync in progress...
                            </MDTypography>
                            <CircularProgressWithLabel value={finalPipelineConfig?.loadingProcess} />
                          </>
                        ) : (
                          <>
                            {smartTime(finalPipelineConfig?.lastSync ?? finalPipelineConfig?.enableDate, true) && (
                              <MDTypography variant="body2" style={{ color: "green", fontWeight: "500" }}>
                                Last sync {smartTime(finalPipelineConfig?.lastSync ?? finalPipelineConfig?.enableDate, true)}
                              </MDTypography>
                            )}

                            <IconButton
                              onClick={async () => {
                                getOpportunities()
                              }}
                            >
                              <Sync />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                )}
                <LastUpdated lastActivity={finalPipelineConfig?.lastInteraction} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  type="submit"
                  style={{ textTransform: "none", height: "40px" }}
                  onClick={() => setShowClosed(!showClosed)}
                >
                  <Icon
                    style={{
                      fontSize: "1.2rem",
                      color: showClosed ? "black" : "lightgrey"
                    }}
                  >
                    disabled_visible
                  </Icon>
                  &nbsp;
                  {showClosed ? "Hide" : "Show"} Closed Deals
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  style={{ textTransform: "none", height: "40px" }}
                  onClick={handleOpenDrawer}
                >
                  + Add Opportunity
                </MDButton>
              </Stack>
            </MDBox>
            <OpportunitiesTable />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

const LastUpdated = ({ lastActivity }: { lastActivity: Date | null }) => {
  // Context
  const { opportunities } = useContext(OpportunitiesContext)

  return opportunities.length > 0 ? (
    <MDTypography variant="body2" fontWeight="normal" fontSize="small">
      {`Last activity: ${smartTime(lastActivity)}`}
    </MDTypography>
  ) : (
    <></>
  )
}

const OpportunitiesTable = () => {
  // Context
  const { warmthAlgorithm } = useContext(RemoteConfigContext)
  const { opportunities, showClosed } = useContext(OpportunitiesContext)
  const { contacts, getPossibleUserContacts } = useContext(ContactsContext)
  const { getCurrentUser } = useContext(AuthContext)
  const { user } = getCurrentUser()

  // Navigation
  const navigate = useNavigate()

  // State
  const [tableData, setTableData] = useState([])
  const [possibleUserContactsIds, setPossibleUserContactsIds] = useState<string[]>(getPossibleUserContacts().map(e => e.id))

  useEffect(() => {
    setTableData(getRows(opportunities))
  }, [opportunities, showClosed])

  useEffect(() => {
    setPossibleUserContactsIds(getPossibleUserContacts().map(e => e.id))
    setTableData(getRows(opportunities))
  }, [contacts])

  const clickOverviewHandler = (id: string) => {
    navigate(`/pipeline/opportunities/${id}`)
  }

  const clickContactHandler = (e: any, id: string) => {
    e.stopPropagation()
    navigate(`/people/relationships/${id}`)
  }

  // Sorting
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([])
  const handleSort = (columnId: string) => {
    setSorting(prevSorting => {
      const isCurrentlySorted = prevSorting.find(s => s.id === columnId)
      if (isCurrentlySorted) {
        // Toggle between ascending, descending, and no sort
        if (isCurrentlySorted.desc) {
          return prevSorting.filter(s => s.id !== columnId) // Remove sort
        } else {
          return prevSorting.map(s => (s.id === columnId ? { ...s, desc: true } : s)) // Descending
        }
      } else {
        // Default to ascending sort
        return [{ id: columnId, desc: false }]
      }
    })
  }

  const getRows = (info: ForgeOpportunity[]) => {
    let updatedInfo = info
      .filter(e => showClosed || !(e.properties.stageDetails?.stage?.metadata?.isClosed ?? false))
      .map(opportunity => {
        let USDollar = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })

        let decisionMaker = opportunity.getWarmestContact(getPossibleUserContacts(), warmthAlgorithm, user.uid) ?? opportunity.decisionMaker
        let opportunityOwner = opportunity.opportunityOwner

        let color = "lightgray"
        let score
        if (possibleUserContactsIds.includes(opportunityOwner?.id) && decisionMaker) {
          score = getWarmthScore(decisionMaker, warmthAlgorithm, user.uid).get("finalScore")
          score = isNaN(score) ? 0 : score
          color = getColorWithGradient(score)
        } else if (possibleUserContactsIds.includes(decisionMaker?.id) && opportunityOwner) {
          score = getWarmthScore(opportunityOwner, warmthAlgorithm, user.uid).get("finalScore")
          score = isNaN(score) ? 0 : score
          color = getColorWithGradient(score)
        }

        return {
          type: "opportunity",
          id: opportunity.id,
          name: opportunity.properties?.name,
          company: opportunity.properties?.company,
          companyName: opportunity.properties?.company?.name,
          decisionMakerId: decisionMaker ? decisionMaker.id : undefined,
          decisionMakerName: decisionMaker ? decisionMaker.name : undefined,
          decisionMaker,
          opportunityOwnerId: opportunityOwner ? opportunityOwner.id : undefined,
          opportunityOwnerName: opportunityOwner ? opportunityOwner.name : undefined,
          opportunityOwner,
          stage: opportunity.properties?.stageDetails?.stage?.label,
          stageValue: opportunity?.getPercentageFromStageOpportunity(false),
          amount: USDollar.format(opportunity.properties?.valueAmount),
          closingDate: opportunity.properties?.closeDate,
          warmth: score,
          warmthColor: color,
          sourceData: opportunity,
          updatedAt: opportunity.updatedAt
        }
      })

    return updatedInfo
  }

  const columns = [
    {
      Header: "opportunity owner",
      accessor: "opportunityOwnerName",
      width: "50px",
      padding: { right: 0 },
      paddingHeader: { right: 2 },
      align: "right",
      sorted: true,
      Cell: (info: any) => {
        return info.cell.row.original.opportunityOwner ? (
          <Card
            variant="outlined"
            style={{
              boxShadow: "none",
              border: "1.5px solid black",
              width: "210px"
            }}
            onClick={e => clickContactHandler(e, info.cell.row.original.opportunityOwnerId)}
          >
            <MDBox display="flex" alignItems="center" m={1}>
              <ForgeContactAvatar
                contact={info.cell.row.original.opportunityOwner}
                enableScoreBadge={false}
                warmthRingThickness={0}
                color="white"
                background="black"
              />
              <MDBox ml={1} style={{ textAlign: "start", maxWidth: "70%" }}>
                <MDBox
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "black"
                  }}
                >
                  {info.cell.row.original.opportunityOwner?.name}
                </MDBox>
                <MDBox
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "gray"
                  }}
                >
                  {info.cell.row.original.opportunityOwner?.jobTitle}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          <MDBox display="flex" alignItems="center">
            <Avatar style={{ background: "lightgray" }}>
              <Person style={{ color: "white" }} />
            </Avatar>
            <MDTypography variant="p2" color="text" sx={{ ml: 2, color: "lightgray" }}>
              Unassigned
            </MDTypography>
          </MDBox>
        )
      }
    },
    {
      Header: "warmth",
      accessor: "warmth",
      width: "5%",
      widthSetting: "100%",
      sorted: true,
      padding: { left: 0, right: 0 },
      paddingHeader: { left: 0 },
      sortDescFirst: true,
      Cell: (info: any) => {
        let score = info.cell.row.original.warmth
        let color = info.cell.row.original.warmthColor

        const [openWarmthDialog, setOpenWarmthDialog] = useState<boolean>(false)
        const handleClose = () => setOpenWarmthDialog(false)

        return info.cell.row.original.decisionMakerId && info.cell.row.original.opportunityOwnerId ? (
          <Stack direction="column" alignItems="center" spacing={1} style={{ position: "relative", width: "100%" }}>
            {isNaN(score) ? (
              <Avatar style={{ width: "32px", height: "32px", position: "absolute", zIndex: "1", marginTop: "-12px" }}>
                <QuestionMark style={{ color: "white" }} fontSize="medium" />
              </Avatar>
            ) : (
              <>
                <ContactWarmthDialog
                  open={openWarmthDialog}
                  onClose={handleClose}
                  userContact={info.cell.row.original.opportunityOwner}
                  contact={info.cell.row.original.decisionMaker}
                  isOwnUser={possibleUserContactsIds.includes(info.cell.row.original.opportunityOwnerId)}
                  relationshipScore={score}
                  relationshipColor={color}
                />
                <Avatar
                  style={{
                    width: "32px",
                    height: "32px",
                    position: "absolute",
                    zIndex: "1",
                    marginTop: "-12px",
                    background: color,
                    color: score >= 20 && score < 80 ? "black" : "white",
                    fontSize: "16px"
                  }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpenWarmthDialog(true)
                  }}
                >
                  {score}
                </Avatar>
              </>
            )}
            <div
              style={{
                width: "100%",
                height: "10px" /* Adjust the height as needed */,
                background: color,
                marginTop: 0
              }}
            ></div>
          </Stack>
        ) : (
          <></>
        )
      }
    },
    {
      Header: "key client",
      accessor: "decisionMakerName",
      width: "50px",
      padding: { left: 0 },
      paddingHeader: { left: 1 },
      sorted: true,
      Cell: (info: any) => {
        return info.cell.row.original.decisionMakerId ? (
          <Card
            variant="outlined"
            style={{
              boxShadow: "none",
              border: "1.5px solid black",
              width: "200px"
            }}
            onClick={e => clickContactHandler(e, info.cell.row.original.decisionMakerId)}
          >
            <MDBox display="flex" alignItems="center" m={1}>
              <ForgeContactAvatar
                contact={info.cell.row.original.decisionMaker}
                enableScoreBadge={false}
                warmthRingThickness={0}
                color="white"
                background="black"
              />

              <MDBox ml={1} style={{ maxWidth: "70%" }}>
                <MDBox
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "black"
                  }}
                  ml={0}
                >
                  {info.cell.row.original.decisionMaker?.name}
                </MDBox>
                <MDBox
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "gray"
                  }}
                >
                  {info.cell.row.original.decisionMaker?.jobTitle}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          <MDBox display="flex" alignItems="center">
            <Avatar style={{ background: "lightgray" }}>
              <Person style={{ color: "white" }} />
            </Avatar>
            <MDTypography variant="p2" color="text" sx={{ ml: 2, color: "lightgray" }}>
              Unassigned
            </MDTypography>
          </MDBox>
        )
      }
    },
    {
      Header: "account name",
      accessor: "companyName",
      width: "10%",
      sorted: true,
      Cell: (info: any) => {
        return (
          <MDBox display="flex" alignItems="center">
            <Tooltip title={info.cell.row.original.company?.name}>
              <Avatar
                variant="rounded"
                alt={info.cell.row.original.company?.name}
                src={info.cell.row.original.company?.imageUrl}
                style={{ background: info.cell.row.original.company ? "black" : "lightgray" }}
              >
                <ApartmentRounded style={{ color: "white" }} />
              </Avatar>
            </Tooltip>
            {info.cell.row.original.company?.name ? (
              <Box sx={{ ml: 2 }}>{info.cell.row.original.company?.name}</Box>
            ) : (
              <MDTypography variant="p2" color="text" sx={{ ml: 2, color: "lightgray" }}>
                No Account
              </MDTypography>
            )}
          </MDBox>
        )
      }
    },
    {
      Header: "opportunity name",
      accessor: "name",
      width: "10%",
      sorted: true,
      Cell: (info: any) => {
        return (
          <MDBox display="flex" alignItems="center">
            {info.cell.row.original?.name}
          </MDBox>
        )
      }
    },
    {
      Header: "stage",
      accessor: "stage",
      width: "10%",
      sorted: true,
      Cell: (info: any) => {
        return info.cell.row.original.stage ? (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDBox style={{ width: "40px", height: "40px" }}>
              <Pie
                style={{ position: "relative", top: "-4px", marginRight: 4 }}
                options={{
                  animation: {
                    duration: 0
                  }
                }}
                data={{
                  labels: [],
                  datasets: [
                    {
                      label: [],
                      weight: 9,
                      cutout: 0,
                      tension: 0.9,
                      pointRadius: 2,
                      borderWidth: 2,
                      borderColor: "black",
                      backgroundColor: ["dark", "white"],
                      fill: false,
                      data: [info.cell.row.original.stageValue, 100 - info.cell.row.original.stageValue]
                    }
                  ]
                }}
              />
            </MDBox>
            {info.cell.row.original.stage}
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDBox style={{ width: "40px", height: "40px" }}>
              <Pie
                style={{ position: "relative", top: "-4px", marginRight: 4 }}
                options={{
                  animation: {
                    duration: 0
                  }
                }}
                data={{
                  labels: [],
                  datasets: [
                    {
                      label: [],
                      weight: 9,
                      cutout: 0,
                      tension: 0.9,
                      pointRadius: 2,
                      borderWidth: 1,
                      borderColor: "lightgray",
                      backgroundColor: ["lightgray", "white"],
                      fill: false,
                      data: [0, 100 - info.cell.row.original.stageValue]
                    }
                  ]
                }}
              />
            </MDBox>
            <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
              No Stage
            </MDTypography>
          </Stack>
        )
      }
    },
    { Header: "amount", accessor: "amount", width: "10%", sorted: true },
    {
      Header: "closing date",
      accessor: "closingDate",
      width: "10%",
      paddingHeader: { right: 2 },
      sorted: true,
      Cell: (info: any) => {
        return info.cell.row.original.closingDate ? (
          <Box>{new Date(info.cell.row.original.closingDate).toDateString()}</Box>
        ) : (
          <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
            No Closing Date
          </MDTypography>
        )
      }
    },
    {
      Header: "update date",
      accessor: "updatedAt",
      width: "10%",
      paddingHeader: { right: 2 },
      sorted: true,
      Cell: (info: any) => {
        return info.cell.row.original.updatedAt ? (
          <Box>{new Date(info.cell.row.original.updatedAt).toDateString()}</Box>
        ) : (
          <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
            No Closing Date
          </MDTypography>
        )
      }
    }
    // { Header: "next action", accessor: "nextAction", width: "10%" },
  ]

  const dataTableData = {
    columns,
    rows: tableData,
    sorting,
    onSort: handleSort
  }

  return (
    <DataTable
      tableId="opportunities"
      table={dataTableData}
      onRowClick={(row, index) => clickOverviewHandler(row.original.id)}
      canSearch
      noEndBorder
    />
  )
}

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" color="success" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default OpportunitiesManagement
