import { createContext, useContext, useState, useEffect } from "react"
import { AuthContext, ContactsContext } from "context"
import { DocumentReference } from "firebase/firestore"
import { Call } from "types/pipeline/call"
import CallsFirestore from "./firestore"

interface CallsContextType {
  calls: Call[]
  getEvent(callId: string): Call | null
  getCalls(callIds: string[]): Call[]
  matchContacts(calls: Call[]): Call[]
}

export const CallsContext = createContext<CallsContextType>({
  calls: [],
  getEvent: (callId: string): any => null,
  getCalls: (callIds: string[]): any[] => [],
  matchContacts: (calls: Call[]): Call[] => []
})

export const CallsContextProvider = ({
  opportunityId,
  contact,
  children,
  isContactPage = false
}: {
  opportunityId?: string
  contact?: any
  children: any
  isContactPage?: boolean
}) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)

  // State
  const [calls, setCalls] = useState<Call[]>([])
  const [personalCalls, setPersonalCalls] = useState<Call[]>([])
  const [organizationCalls, setOrganizationCalls] = useState<Call[]>([])

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const callsFirestore = new CallsFirestore(user, userProfileData, encryptionService)

  useEffect(() => {
    setCalls([...personalCalls, ...organizationCalls])
  }, [personalCalls, organizationCalls])

  useEffect(() => {
    setPersonalCalls([])
    if (isContactPage && !contact?.ref) return
    const unsubscribe = callsFirestore.getPersonalCallsLive(newCalls => setPersonalCalls(newCalls), opportunityId, contact?.ref)

    return () => unsubscribe()
  }, [isEncryptionInitialized, opportunityId, contact?.ref?.id, isContactPage])

  useEffect(() => {
    if (userProfileData?.doesUserBelongsToAnOrganization) {
      setOrganizationCalls([])
      const unsubscribe = callsFirestore.getOrganizationCallsLive(
        newCalls => {
          setOrganizationCalls(newCalls)
        },
        opportunityId,
        contact?.ref
      )

      return () => unsubscribe()
    }
  }, [userProfileData?.organization?.id, opportunityId, contact?.ref?.id])

  useEffect(() => {
    setCalls(matchContacts(calls))
  }, [calls, contacts])

  const matchContacts = (calls: Call[]): Call[] => {
    for (const call of calls) {
      call.contacts = getContacts(call.contactRefs?.map((e: DocumentReference) => e.id) ?? [])
    }

    return calls
  }

  const getEvent = (callId: string): any => {
    return calls.find(call => call.ref?.id === callId)
  }

  const getCalls = (callIds: string[]): any[] => {
    let selectedCalls = []

    for (const callId of callIds) {
      selectedCalls.push(calls.find(call => call.ref?.id === callId))
    }

    return selectedCalls.filter(e => e)
  }

  return <CallsContext.Provider value={{ calls, getEvent, getCalls, matchContacts }}>{children}</CallsContext.Provider>
}
