// MUI
import { Icon, Paper, Stack } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { CalendarEvent } from "types/calendar/calendar-event"
import { HandshakeOutlined } from "@mui/icons-material"
import { ForgeAvatarGroup } from "../ForgeAvatar"
import borders from "assets/theme/base/borders"
import linearGradient from "assets/theme/functions/linearGradient"
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer"
import { useContext, useEffect, useState } from "react"
import { OpportunitiesContext } from "forge/pipeline/opportunities/services/OpportunitiesContext"
import { ForgeOpportunity } from "types/pipeline/opportunity"
import { Call } from "types/pipeline/call"
import { AuthContext } from "forge/auth/services/AuthContext"
import CallsFirestore from "forge/pipeline/calls/services/firestore"
import MDSnackbar from "components/MDSnackbar"
import { ColorType, SnackbarProps } from "types/snackbar"
import { createPortal } from "react-dom"
import { CallsContext } from "forge/pipeline/calls/services/CallsContext"

const { borderRadius } = borders
function EventCard({ event }: { event: CalendarEvent }): JSX.Element {
  // Navigation
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { calls, matchContacts } = useContext(CallsContext)
  const { opportunities, getOpportunity } = useContext(OpportunitiesContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const callsFirestore = new CallsFirestore(user, userProfileData, encryptionService)

  // State
  const [opportunity, setOpportunity] = useState<ForgeOpportunity>(getOpportunity(event.opportunityId))
  const [call, setCall] = useState<Call>()
  const [isSameEvent, setIsSameEvent] = useState(false)
  const [openCreateCallDrawer, setOpenCreateCallDrawer] = useState(false)
  const handleOpenCallDrawer = () => setOpenCreateCallDrawer(true)
  const handleCloseCallDrawer = () => setOpenCreateCallDrawer(false)

  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    open: false,
    color: "info",
    title: "",
    message: ""
  })

  const onCardTapped = async (eventId: string) => {
    let callSet = false
    // If event is attached to an Opportunity and has passed, log a conversation
    if (event.opportunityId != null && event.hasPassed) {
      if (opportunity) {
        let contacts = (event.contacts || []).filter(contact => opportunity.allRelatedContactsIds.includes(contact.ref?.id))
        setCall(
          new Call({
            subject: event.name,
            details: "",
            date: event.startDate?.toDate(),
            contacts: contacts,
            opportunityId: opportunity?.id,
            contactRefs: contacts.map(e => e.ref),
            opportunity: opportunity,
            isProcessing: false
          })
        )
        callSet = true
      }
    }

    if (event.callRef && (event.hasPassed || isSameEvent)) {
      // First check in our already saved calls
      let referenceCall = calls.find(call => event.callRef.id === call.ref.id)
      if (!referenceCall) {
        referenceCall = await callsFirestore.getCall(event.callRef.id)
        referenceCall = matchContacts([referenceCall])?.[0] ?? undefined
      }

      if (referenceCall) {
        setCall(referenceCall)
        callSet = true
      }
    }

    if (callSet) {
      handleOpenCallDrawer()
      return
    }

    navigate(`/calendar/events/${eventId}`)
  }

  const handleSnackbar = (color: ColorType, title: string, message: string) =>
    setSnackbar({
      open: true,
      color,
      title,
      message
    })

  const handleCloseSnackbar = () => setSnackbar(prev => ({ ...prev, open: false }))

  const renderSnackbar = createPortal(
    <MDSnackbar
      color={snackbar.color}
      icon="notifications"
      dateTime=""
      title={snackbar.title}
      content={snackbar.message}
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      close={handleCloseSnackbar}
      bgWhite
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: 1500
      }}
    />,
    document.body
  )

  useEffect(() => {
    setOpportunity(getOpportunity(event.opportunityId))
  }, [opportunities])

  useEffect(() => {
    if (location.pathname.includes("/calendar/events") && id === event.eventId) setIsSameEvent(true)
  }, [location, id, event])

  return (
    <>
      {renderSnackbar}
      <MDBox mb={2}>
        <CreateCallDrawer
          openDrawer={openCreateCallDrawer}
          handleCloseDrawer={handleCloseCallDrawer}
          opportunity={opportunity}
          call={call}
          event={event}
          showSnackbar={handleSnackbar}
        />
        <Paper elevation={2} style={{ borderRadius: 12, cursor: "pointer" }} onClick={() => onCardTapped(event.eventId)}>
          <MDBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor={"grey-100"}
            borderRadius="lg"
            px={2}
            py={event?.isAllDay && event.contacts?.length === 0 ? 1 : 2}
            style={
              event.isInvite
                ? {
                    backgroundImage: linearGradient(
                      `#f8f9fa 25%,
                            lightgray 25%,
                            lightgray 50%,
                            #f8f9fa 50%,
                            #f8f9fa 75%,
                            lightgray 75%`,
                      "lightgray 100%",
                      -45
                    ),
                    backgroundSize: "64px 64px"
                  }
                : {}
            }
          >
            <Stack direction="row" sx={{ width: "100%", "& > :first-child": { width: "4px" } }}>
              <MDBox mr={1.5} style={{ background: event?.color(), borderRadius: borderRadius.lg }} />
              <MDBox width="100%" flexDirection="column">
                <MDBox display="flex" justifyContent="space-between" alignItems="center" color="dark">
                  {event?.isAllDay ? (
                    <MDBox />
                  ) : (
                    <MDTypography mb={0.5} variant="caption" color="text">
                      {event.startDate?.toDate().toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true
                      })}{" "}
                      -{" "}
                      {event.endDate?.toDate().toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true
                      })}
                    </MDTypography>
                  )}
                  {event.opportunityId && <HandshakeOutlined />}
                </MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1
                    }}
                    style={{
                      textDecoration: event.isDeclined ? "line-through" : "none",
                      color: event.hasContacts && !event.isDeclined ? "black" : "#80808096"
                    }}
                  >
                    {event.name}
                  </MDTypography>
                </MDBox>
                {event.hasContacts && (
                  <MDBox mb={1} lineHeight={0}>
                    <MDTypography
                      variant="caption"
                      style={{
                        textDecoration: event.isDeclined ? "line-through" : "none",
                        color: event.hasContacts && !event.isDeclined ? "black" : "#80808096"
                      }}
                    >
                      With {event.contacts[0]?.name}
                      {event.contacts.length > 1 ? ` and ${event.contacts.length - 1} other${event.contacts.length - 1 === 1 ? "" : "s"}` : ""}
                    </MDTypography>
                  </MDBox>
                )}

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  {event.hasContacts && <ForgeAvatarGroup contacts={event.contacts} event={event} />}
                  {(event.isInvite || (event.contacts?.length !== 0 && !isSameEvent)) && (
                    <MDButton
                      variant="text"
                      color={"info"}
                      onClick={() => onCardTapped(event.eventId)}
                      size="small"
                      style={{ textTransform: "none", fontWeight: "400", padding: 0, fontSize: "0.875rem" }}
                    >
                      {event.cardCta}&nbsp;<Icon>arrow_forward</Icon>
                    </MDButton>
                  )}
                </MDBox>
              </MDBox>
            </Stack>
          </MDBox>
        </Paper>
      </MDBox>
    </>
  )
}

// Setting default values for the props of EventCard
EventCard.defaultProps = {
  event: null
}

export default EventCard
