import { createContext, useContext, useState, useEffect, useRef } from "react"
import { AuthContext } from "context"
import { Company } from "types/company"
import { AppStateContext } from "forge/core/services/AppStateContext"
import { SplashScreen } from "forge/core/components/SplashScreen"
import ContactsFirestore from "./firestore"
import { firestoreDb } from "firebase.init"
import { doc, getDoc } from "firebase/firestore"
import OrganizationMembersApi from "forge/organization/members/services/api"
import ContactsApi from "./api"

export const ContactsContext = createContext({
  contacts: [],
  draftContacts: [],
  contactsMap: new Map(),
  migrationProgress: 0,
  getContact: (contactId: string): any => null,
  getContacts: (contactIds: string[]): any[] => [],
  getDraftContacts: (contactIds: string[]): any[] => [],
  getDraftContactsByEmail: (contactEmails: string[]): any[] => [],
  getContactsCompanies: (): Company[] => [],
  getCompanyContacts: (company: string): any[] => [],
  getActiveSearchTerms: (): { [key: string]: string[] } => ({}),
  getPossibleUserContacts: (): any[] => [],
  changeContactDraftStatus: (contactRefs: string[]) => {}
})

export const ContactsContextProvider = ({ children }: { children: any }) => {
  // Context
  const { contactsLoading, setContactsLoading } = useContext(AppStateContext)
  const { userProfileData, getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const userProfileDataRef = useRef(userProfileData)

  // State
  const [contacts, setContacts] = useState([])
  const [contactsMap, setContactsMap] = useState<Map<string, any>>(new Map())
  const [personalContacts, setPersonalContacts] = useState([])
  const [draftContacts, setDraftContacts] = useState([])
  const [personalContactsMap, setPersonalContactsMap] = useState<Map<string, any>>(new Map())
  const [organizationContacts, setOrganizationContacts] = useState([])
  const [organizationContactsMap, setOrganizationContactsMap] = useState<Map<string, any>>(new Map())
  const [migrationProgress, setMigrationProgress] = useState<number>(0)

  // Services
  const { user, encryptionService } = getCurrentUser()
  const contactsFirestore = new ContactsFirestore(user, userProfileDataRef.current, encryptionService)
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  useEffect(() => {
    userProfileDataRef.current = userProfileData
  }, [userProfileData])

  useEffect(() => {
    setContacts([...personalContacts, ...organizationContacts])
  }, [personalContacts, organizationContacts])

  useEffect(() => {
    setContactsMap(new Map([...personalContactsMap, ...organizationContactsMap]))
  }, [personalContactsMap, organizationContactsMap])

  useEffect(() => {
    if (user) {
      contactsFirestore.getPersonalContactsDraft(contacts => {
        setDraftContacts(contacts)
      })

      return contactsFirestore.getPersonalContactsLive(contacts => {
        const contactsMapped = contacts.reduce<Map<string, any>>((acc, currentValue, currentIndex, __) => {
          acc.set(currentValue.id, currentValue)
          return acc
        }, new Map())

        setPersonalContactsMap(contactsMapped)
        setPersonalContacts(contacts)
        setContactsLoading(false)

        // Check for possible contacts with organization knowledge that needs to be migrated
        // Run with requestIdleCallback if available, falling back to a regular invocation
        if (window.requestIdleCallback) {
          requestIdleCallback(() => migrateOrganizationKnowledge(contacts))
        } else {
          migrateOrganizationKnowledge(contacts)
        }
      })
    } else {
      setContactsLoading(false)
    }
  }, [isEncryptionInitialized])

  useEffect(() => {
    if (userProfileDataRef.current?.doesUserBelongsToAnOrganization) {
      return contactsFirestore.getOrganizationContactsLive(contacts => {
        const contactsMapped = contacts.reduce<Map<string, any>>((acc, currentValue, currentIndex, __) => {
          acc.set(currentValue.id, currentValue)
          return acc
        }, new Map())

        setOrganizationContactsMap(contactsMapped)
        setOrganizationContacts(contacts)
        // setContactsLoading(false);
      })
    } else {
      // setContactsLoading(false);
      setOrganizationContactsMap(new Map())
      setOrganizationContacts([])
    }
  }, [userProfileDataRef.current?.organization?.id, userProfileDataRef.current?.organization?.pendingMigration])

  const BATCH_INTERVAL = 2000 // 2-second delay between batches
  const migrateOrganizationKnowledge = async (contacts: any[]) => {
    if (userProfileDataRef.current?.organization?.id && userProfileDataRef.current?.organization?.pendingMigration) {
      // Organization
      const organizationId = userProfileDataRef.current?.organization?.id
      const organizationRef = doc(firestoreDb, `organizations/${organizationId}`)
      const organizationDoc = await getDoc(organizationRef)

      if (organizationDoc.exists()) {
        const contactsToMigrate = contacts.filter(contact => contact.copyStatus)
        const contactsPending = contactsToMigrate.filter(contact => contact.copyStatus === "pending")
        setMigrationProgress(contactsPending.length / contactsToMigrate.length)

        for (let i = 0; i < contactsPending.length; i++) {
          const contactDoc = contactsPending[i]
          await contactsFirestore.migrateOrganizationContactKnowledge(contactDoc, organizationRef)

          // Delay next batch to prevent system overload
          await new Promise(resolve => setTimeout(resolve, BATCH_INTERVAL))

          setMigrationProgress((contactsPending.length - i) / contactsToMigrate.length)
        }
      }

      // Auto remove member
      const organizationMembersApi = new OrganizationMembersApi(user, userProfileDataRef.current, encryptionService)
      const result = await organizationMembersApi.autoRemove()
      if (result.removeFromSealdGroup && result.sealdGroupId) {
        await encryptionService.removeMemberFromGroup(result.sealdGroupId, userProfileDataRef.current?.encryption?.sealdIdentity)
      }
    }
  }

  const getContact = (contactId: string): any => {
    return contactsMap.get(contactId)
  }

  const getContacts = (contactIds: string[]): any[] => {
    let contacts = []

    for (const contactId of contactIds) {
      contacts.push(contactsMap.get(contactId))
    }

    return contacts.filter(e => e)
  }

  const getDraftContacts = (contactIds: string[]): any[] => {
    return draftContacts.filter(e => contactIds.includes(e?.ref?.id))
  }

  const getDraftContactsByEmail = (contactEmails: string[]): any[] => {
    if (contactEmails.length === 0) return []
    return draftContacts.filter(e => e?.emailStrings?.some((email: string) => contactEmails.includes(email)))
  }

  const getContactsCompanies = (): Company[] => {
    // Filtering out forged contacts with non-empty experiences and mapping them to Company objects
    const companies: Company[] = []
    contacts
      .filter(e => (e.linkedInProfileData?.experiences != null && e.linkedInProfileData.experiences.length > 0) || e.company)
      .forEach(e => {
        if (e.linkedInProfileData?.experiences != null && e.linkedInProfileData.experiences.length > 0) {
          // A use could have 1 or more current experiences so we will evaluate the ones were ends_at field is null
          e.linkedInProfileData.experiences
            .filter((e: any) => e.ends_at === null)
            .forEach((experience: any) => {
              const experienceData = {
                id: "",
                name: experience.company || "",
                imageUrl: experience.logoUrl
              }
              companies.push(experienceData)
            })
        }

        // Also we could have only the company in the useData
        const company = {
          id: "",
          name: e.company || "",
          imageUrl: ""
        }
        companies.push(company)
      })

    // Removing duplicates based on company name
    const seen = new Set()
    const uniqueCompanies = companies.filter(company => {
      const isDuplicate = seen.has(company.name)
      seen.add(company.name)
      return !isDuplicate
    })

    // Sorting the list alphabetically by company name
    uniqueCompanies.sort((a, b) => a.name.localeCompare(b.name))

    return uniqueCompanies
  }

  const getCompanyContacts = (company: string) => {
    return contacts.filter(contact => contact?.company?.toLowerCase() === company?.toLowerCase())
  }

  const getActiveSearchTerms = (): { [key: string]: string[] } => {
    const termsMap: { contactRef: string | null; searchTerms: string[] }[] = contacts.map(e => ({
      contactRef: e.ref?.id,
      searchTerms: e.searchTermsInUse
    }))

    const searchTerms: { [key: string]: string[] } = {}
    for (const term of termsMap) {
      if (term.contactRef && term.searchTerms && term.searchTerms.length > 0) {
        for (const finalTerm of term.searchTerms) {
          if (searchTerms[finalTerm]) {
            searchTerms[finalTerm].push(term.contactRef!)
          } else {
            searchTerms[finalTerm] = [term.contactRef!]
          }
        }
      }
    }

    return searchTerms
  }

  const getPossibleUserContacts = (): any[] => {
    const meContact = personalContactsMap.get(user.uid)
    if (meContact) {
      return [meContact]
    }

    return personalContacts.filter(
      e => e.emailStrings?.some((email: string) => userProfileDataRef.current?.emails?.some(userEmail => userEmail.email === email))
    )
  }

  const changeContactDraftStatus = async (contactRefs: string[]) => {
    const contactPromises: Promise<any>[] = []

    try {
      for (const contactRef of contactRefs) {
        contactPromises.push(
          contactsApi.updateContact({
            ref: contactRef,
            status: "live"
          })
        )
      }

      await Promise.allSettled(contactPromises)
    } catch (error) {
      console.error(error)
    }
  }

  if (contactsLoading) {
    return <SplashScreen />
  }

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        draftContacts,
        contactsMap,
        migrationProgress,
        getContact,
        getContacts,
        getDraftContacts,
        getDraftContactsByEmail,
        getContactsCompanies,
        getCompanyContacts,
        getActiveSearchTerms,
        getPossibleUserContacts,
        changeContactDraftStatus
      }}
    >
      {children}
    </ContactsContext.Provider>
  )
}
