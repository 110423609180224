import { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import theme from "assets/theme"

// @mui material components
import { Drawer, Grid, useMediaQuery } from "@mui/material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"

// Components
import DealHeaderCard from "../components/DealHeaderCard"
import DealActivities from "../components/DealActivities"
import DealPeople from "../components/DealPeople"
import CreateOpportunity from "../components/CreateOpportunity"
import CreateEventDrawer from "forge/calendar/components/CreateEventDrawer"
import DealDetails from "../components/DealDetails"
import CreateCallDrawer from "forge/pipeline/calls/components/CreateCallDrawer"
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer"
import { OpportunityContext, OpportunityContextProvider } from "../services/OpportunityContext"
import { CallsContextProvider } from "../../calls/services/CallsContext"
import { CommitmentsContextProvider } from "forge/commitments/services/CommitmentsContext"
import MDSnackbar from "components/MDSnackbar"
import { ColorType, SnackbarProps } from "types/snackbar"
import { createPortal } from "react-dom"

function DealOverview(): JSX.Element {
  const { id } = useParams()

  return (
    <OpportunityContextProvider opportunityId={id}>
      <CallsContextProvider opportunityId={id}>
        <CommitmentsContextProvider opportunityId={id}>
          <OpportunityBody />
        </CommitmentsContextProvider>
      </CallsContextProvider>
    </OpportunityContextProvider>
  )
}

const OpportunityBody = () => {
  const { opportunity } = useContext(OpportunityContext)

  // State
  const [openCreateOpportunityDrawer, setOpenCreateOpportunityDrawer] = useState(false)
  const [openCreateEventDrawer, setOpenCreateEventDrawer] = useState(false)
  const [openCreateCallDrawer, setOpenCreateCallDrawer] = useState(false)
  const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false)

  // Snackbar
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    color: ColorType;
    title: string;
    message: string;
  }>({
    open: false,
    color: 'info',
    title: '',
    message: '',
  });

  const handleOpenDrawer = () => setOpenCreateOpportunityDrawer(true)
  const handleCloseDrawer = () => setOpenCreateOpportunityDrawer(false)
  const handleOpenEventDrawer = () => setOpenCreateEventDrawer(true)
  const handleCloseEventDrawer = () => setOpenCreateEventDrawer(false)
  const handleOpenCallDrawer = () => setOpenCreateCallDrawer(true)
  const handleCloseCallDrawer = () => setOpenCreateCallDrawer(false)
  const handleOpenCommitmentDrawer = () => setOpenCreateCommitmentDrawer(true)
  const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false)

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  const handleSnackbar = (color: ColorType, title: string, message: string) => {
    setSnackbar({
      open: true,
      color,
      title,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderSnackbar = createPortal(
    <MDSnackbar
      color={snackbar.color}
      icon="notifications"
      dateTime=""
      title={snackbar.title}
      content={snackbar.message}
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      close={handleCloseSnackbar}
      bgWhite
      style={{
        position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: 1500,
      }}
    />,
    document.body
  );
  
  return (
    <>
    {renderSnackbar}
    <DashboardLayout>
      <Drawer
        anchor="right"
        open={openCreateOpportunityDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
          }
        }}
      >
        <CreateOpportunity handleCloseDrawer={handleCloseDrawer} opportunity={opportunity} />
      </Drawer>
      <CreateEventDrawer openDrawer={openCreateEventDrawer} handleCloseDrawer={handleCloseEventDrawer} opportunityId={opportunity?.id} />
      <CreateCallDrawer openDrawer={openCreateCallDrawer} handleCloseDrawer={handleCloseCallDrawer} opportunity={opportunity} showSnackbar={handleSnackbar}/>
      <CreateCommitmentDrawer openDrawer={openCreateCommitmentDrawer} handleCloseDrawer={handleCloseCommitmentDrawer} showSnackbar={handleSnackbar} opportunity={opportunity} />
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <DealHeaderCard
              opportunity={opportunity}
              handleOpenDrawer={handleOpenDrawer}
              handleOpenEventDrawer={handleOpenEventDrawer}
              handleOpenCallDrawer={handleOpenCallDrawer}
              handleOpenCommitmentDrawer={handleOpenCommitmentDrawer}
            />
            <MDBox mt={2} />
            <DealDetails opportunity={opportunity} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <DealActivities opportunity={opportunity} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <DealPeople opportunity={opportunity} />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
    </>
  )
}

export default DealOverview
