import { forwardRef, useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { NumericFormat, NumericFormatProps } from "react-number-format"
import { AuthContext, ContactsContext } from "context"

// MUI
import { Autocomplete, Box, Divider, FilterOptionsState, FormControl, Icon, TextField } from "@mui/material"
import { Abc, Apartment, AttachMoney, CalendarMonth, CategoryOutlined, Person, PieChartOutline, PriorityHigh } from "@mui/icons-material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { matchSorter } from "match-sorter"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDInput from "components/MDInput"

// Services
import PipelineApi from "../../services/api"
import { Company } from "types/company"
import { ForgeOpportunity, OpportunityStageOption, OpportunityState, OpportunityType, opportunityTypes } from "types/pipeline/opportunity"

// Images
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { useNavigate } from "react-router-dom"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import { LOADING_MESSAGES } from "forge/shared/constants"
import MDAlert from "components/MDAlert"
import { DEFAULT_STAGES } from "../../helpers/constants"
import { isEmpty } from "lodash"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" }
})

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  )
})

function CreateOpportunity({ handleCloseDrawer, opportunity }: { handleCloseDrawer: () => void; opportunity?: ForgeOpportunity | null }) {
  // UI
  const classes = styles()
  const navigate = useNavigate()

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { contacts, getContact, getContactsCompanies, getPossibleUserContacts } = useContext(ContactsContext)

  // API
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const pipelineApi = new PipelineApi(user, userProfileData, encryptionService)

  // Options
  const [companies, setCompanies] = useState<Company[]>([])

  // State
  const [isSubmited, setIsSubmited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  var future = new Date()
  future.setDate(future.getDate() + 30)

  const [company, setCompany] = useState<Company | null>(() => {
    if (opportunity && opportunity?.properties?.company) return opportunity?.properties?.company
    return null
  })
  const [name, setName] = useState("")
  const [contact, setContact] = useState<any>(() => {
    if (opportunity && opportunity?.decisionMaker) return opportunity.decisionMaker
    return null
  })
  const [stageOptions, setStageOptions] = useState<OpportunityStageOption[]>(Object.values(DEFAULT_STAGES)[0])
  const [stage, setStage] = useState<OpportunityStageOption | null>(() => {
    if (opportunity && opportunity?.properties?.stageDetails?.stage) return opportunity.properties.stageDetails?.stage
    return null
  })
  const [amount, setAmount] = useState("")
  const [closeDate, setCloseDate] = useState<Dayjs>(dayjs(future))
  const [businessType, setBusinessType] = useState<OpportunityType>(opportunityTypes[0])
  const [priority, setPriority] = useState("Medium")

  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const handleOpenDialogDelete = () => setOpenDialogDelete(true)

  useEffect(() => {
    let companies = getContactsCompanies()

    if (opportunity) {
      setName(opportunity.properties.name)

      const exists = companies.some(company => company.domain === opportunity.properties?.company?.domain)
      if (!exists) {
        companies = [...companies, opportunity.properties.company]
      }

      setAmount(opportunity.properties.valueAmount?.toString())
      setCloseDate(dayjs(opportunity.properties.closeDate))
      setBusinessType(opportunity.properties.type ?? opportunityTypes[0])
      setPriority(opportunity.properties.priority ? opportunity.properties.priority : "Medium")

      let decisionMakerId = Object.entries(opportunity.contactsRoles)?.find(([key, value]) => value === "Decision Maker")
      let contact = getContact(decisionMakerId?.[0])

      setContact(contact)
    }

    setCompanies(companies)
  }, [opportunity])

  useEffect(() => {
    ;(async () => {
      if (user) {
        let optionsMap = await pipelineApi.getOpportunityStageOptions()
        let values = Object.values(isEmpty(optionsMap) ? DEFAULT_STAGES : optionsMap)

        if (values.length > 0) {
          setStageOptions(values[0])

          if (opportunity) {
            setStage(opportunity.properties.stageDetails?.stage)
          }
        }
      }
    })()
  }, [user])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setIsSubmited(true)

    if (!name || !stage) {
      return
    }
    setIsSubmited(false)

    setLoading(true)

    if (opportunity) {
      opportunity.properties.name = name
      opportunity.properties.company = company
      opportunity.properties.closeDate = closeDate.toDate()
      opportunity.properties.stageDetails = {
        stage: stage ?? opportunity.properties.stageDetails?.stage,
        expectedEndDate: closeDate.toDate(),
        updatedAt: new Date()
      }
      if (amount) {
        opportunity.properties.valueAmount = parseFloat(amount)
      }
      opportunity.properties.type = businessType
      opportunity.properties.priority = priority
      opportunity.stageOptions = stageOptions
      await pipelineApi.updateOpportunity(opportunity)
      setLoading(false)
      handleCloseDrawer()
      return
    }

    let clientContactsIds: string[] = []
    let contactsRoles: { [key: string]: string } = {}
    if (contact != null) {
      clientContactsIds.push(contact.id)
      contactsRoles[contact.id] = "Decision Maker"
    }

    let newOpportunity = new ForgeOpportunity({
      id: "",
      properties: {
        name: name,
        company: company,
        closeDate: closeDate.toDate(),
        stateDetails: {
          state: OpportunityState.active,
          updatedAt: new Date()
        },
        stageDetails: {
          stage: stage ?? {
            id: "",
            label: "",
            archived: false,
            displayOrder: 0,
            metadata: {}
          },
          expectedEndDate: closeDate.toDate(),
          updatedAt: new Date()
        },
        valueAmount: amount ? parseFloat(amount) : 0,
        type: businessType,
        priority: priority.toLowerCase()
      },
      teamUsersIds: [],
      teamContactsIds: [],
      teamContacts: [],
      clientContactsIds: clientContactsIds,
      clientContacts: [],
      teamClientRelations: {},
      contactsRoles: contactsRoles,
      createdAt: new Date(),
      updatedAt: new Date(),
      stageOptions: stageOptions,
      encrypted: false
    })

    await pipelineApi.createOpportunity(newOpportunity)
    setLoading(false)
    handleCloseDrawer()
  }

  const handleSecondaryAction = async () => {
    if (opportunity) {
      handleOpenDialogDelete()
    } else {
      handleCloseDrawer()
    }
  }

  const handleCloseDialogDelete = async (result: boolean) => {
    setOpenDialogDelete(false)
    if (result) {
      setLoading(true)
      let result = await pipelineApi.deleteOpportunity(opportunity)
      if (result) {
        handleCloseDrawer()
        navigate("/pipeline/opportunities")
      }
    }
  }

  const filterOptions = (options: any[], state: FilterOptionsState<any>) => {
    let filteredContacts = matchSorter(options, state.inputValue, { keys: ["name"] })

    if (company) {
      filteredContacts = filteredContacts.sort((a: any, b: any) => {
        if (a?.company?.toLowerCase() === company.name.toLowerCase()) return -1
        if (b?.company?.toLowerCase() === company.name.toLowerCase()) return 1
        return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
      })
    }
    return filteredContacts
  }

  return (
    <FormControl component="form" onSubmit={handleSubmit} style={{ display: "contents" }}>
      <ConfirmationDialog
        openDialog={openDialogDelete}
        handleCloseDialog={handleCloseDialogDelete}
        title="Are you sure you want to delete this opportunity?"
        description="This is a permanent action."
        confirmText="Delete"
        confirmColor="error"
      />
      <ForgeLoading loading={loading} loadingText={LOADING_MESSAGES.CRM} />

      {/* Header */}
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MDBox>
          <MDTypography variant="h5">{opportunity ? "Edit" : "Create an "} Opportunity</MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)"
          })}
          onClick={handleCloseDrawer}
        >
          close
        </Icon>
      </MDBox>
      <Divider />

      {/* Body */}

      <MDBox style={{ width: "100%", flex: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MDBox px={3}>
            <MDBox className={classes.formTile}>
              <Apartment className={classes.formIcon} />
              <Autocomplete
                id="company"
                sx={{ width: 300 }}
                style={{ flex: 1 }}
                value={company}
                options={companies}
                getOptionLabel={option => option.name}
                onChange={(event: any, newValue: Company) => setCompany(newValue)}
                autoHighlight
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.domain ?? option.name} sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                    <img loading="lazy" width="20" srcSet={`${option.imageUrl} 2x`} src={option.imageUrl} alt="" />
                    {option.name}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Organization"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </MDBox>
            <MDBox className={classes.formTile}>
              <Abc className={classes.formIcon} />
              <TextField
                id="name"
                label="Opportunity Name"
                variant="outlined"
                style={{ flex: 1 }}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </MDBox>
            {isSubmited && !name && (
              <MDBox>
                <MDAlert color="error">Opportunity name is required</MDAlert>
              </MDBox>
            )}

            <MDBox className={classes.formTile}>
              <Person className={classes.formIcon} />
              <Autocomplete
                id="point-of-contact"
                sx={{ width: 300 }}
                style={{ flex: 1 }}
                options={contacts}
                value={contact}
                onChange={(_: any, newValue: any) => setContact(newValue)}
                autoHighlight
                getOptionLabel={option => option?.name}
                getOptionDisabled={option =>
                  getPossibleUserContacts()
                    .map(e => e.ref?.id)
                    .includes(option.ref?.id)
                }
                filterOptions={filterOptions}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.ref?.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                    <MDBox mr={2}>
                      <ForgeContactAvatar contact={option} enableScoreBadge={false} enableImageClick={false} />
                    </MDBox>
                    {option.name}
                  </Box>
                )}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label="Key Client"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )
                }}
              />
            </MDBox>
            {!opportunity && (
              <>
                <MDBox className={classes.formTile}>
                  <PieChartOutline className={classes.formIcon} />
                  <Autocomplete
                    id="stage"
                    style={{ flex: 1 }}
                    options={stageOptions}
                    value={stage}
                    renderInput={params => <MDInput {...params} variant="outlined" label="Stage" />}
                    onChange={(event: any, newValue: any) => setStage(newValue)}
                  />
                </MDBox>

                {isSubmited && !stage && (
                  <MDBox>
                    <MDAlert color="error">Set an initial stage for the opportunity</MDAlert>
                  </MDBox>
                )}
              </>
            )}
            {!opportunity && (
              <MDBox className={classes.formTile}>
                <AttachMoney className={classes.formIcon} />
                <TextField
                  id="amount"
                  label="Amount"
                  variant="outlined"
                  style={{ flex: 1 }}
                  value={amount}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any
                  }}
                  onChange={e => setAmount(e.target.value)}
                />
              </MDBox>
            )}
            {!opportunity && (
              <MDBox className={classes.formTile}>
                <CalendarMonth className={classes.formIcon} />
                <MDTypography variant="body2" style={{ alignSelf: "center" }}>
                  Close Date
                </MDTypography>
                <Box style={{ flex: 1 }} />
                <DatePicker format="MMM DD, YYYY" value={closeDate} onChange={setCloseDate} />
              </MDBox>
            )}
            {!opportunity && (
              <MDBox className={classes.formTile}>
                <CategoryOutlined className={classes.formIcon} />
                <Autocomplete
                  id="business-type"
                  style={{ flex: 1 }}
                  options={opportunityTypes}
                  value={businessType}
                  getOptionLabel={option => option.label}
                  renderInput={params => (
                    <MDInput
                      {...params}
                      variant="outlined"
                      label="Business Type"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(event: any, newValue: any) => setBusinessType(newValue)}
                />
              </MDBox>
            )}
            <MDBox className={classes.formTile}>
              <PriorityHigh className={classes.formIcon} />
              <Autocomplete
                id="priority"
                style={{ flex: 1 }}
                options={["Low", "Medium", "High"]}
                value={priority}
                renderInput={params => (
                  <MDInput
                    {...params}
                    variant="outlined"
                    label="Priority"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password" // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event: any, newValue: any) => setPriority(newValue)}
              />
            </MDBox>
          </MDBox>
        </LocalizationProvider>
      </MDBox>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 1.5,
          pb: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          justifyContent: "space-between"
        }}
      >
        <MDButton
          variant="text"
          color={opportunity ? "error" : "dark"}
          onClick={handleSecondaryAction}
          style={{ textTransform: "capitalize", fontSize: "medium" }}
        >
          {opportunity ? "Delete" : "Clear"}
        </MDButton>
        <MDButton variant="gradient" color="info" type="submit" style={{ textTransform: "capitalize", fontSize: "medium" }}>
          {opportunity ? "Update" : "Create"} Opportunity
        </MDButton>
      </Box>
    </FormControl>
  )
}

// Setting default values for the props of CreateOpportunity
CreateOpportunity.defaultProps = {
  handleCloseDrawer: null
}

export default CreateOpportunity
