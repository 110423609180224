// MUI
import { Icon, Paper, Stack, Switch, Tooltip } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import { parseMilestone } from "forge/core/knowledgeParsing"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"
import { useContext, useEffect, useMemo, useState } from "react"
import { ForgeEvent } from "types/forge-event"
import MDButton from "components/MDButton"
import { AlertsContext } from "forge/home/services/AlertsContext"
import { ContactsContext } from "context"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

function AlertCard({ alert }: { alert: ForgeEvent }): JSX.Element {
  // Context
  const { createContactFromAlert, updateAlert } = useContext(AlertsContext)
  const { getDraftContacts, getContacts } = useContext(ContactsContext)
  // State
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const [contacts, setContacts] = useState([])

  useEffect(() => {
    if (alert) {
      if (alert.contacts.length > 0) {
        const contactsIds = alert.contacts.map(e => e.ref?.id).filter(Boolean)
        setContacts((alert as any).type === "AlertType.contactFound" ? getDraftContacts(contactsIds) : getContacts(contactsIds))
      }
      setCompleted(alert.completed)
    }
  }, [alert])

  const createContactClick = async () => {
    setLoading(true)
    await createContactFromAlert(alert)
    setLoading(false)
  }

  const updateCompletion = async (checked: boolean) => {
    setCompleted(checked)
    alert.completed = checked
    await updateAlert(alert)
  }

  const isContactFoundType = useMemo(() => (alert as any).type === "AlertType.contactFound", [alert])

  return (
    <MDBox mb={2}>
      <Paper elevation={2} style={{ borderRadius: 12 }}>
        <MDBox width="100%" display="flex" flexDirection="column" bgColor={"grey-100"} p={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" maxWidth="88%">
              <MDBox lineHeight={0}>
                <MDTypography variant="h6" fontWeight="medium">
                  {parseMilestone(alert)}
                </MDTypography>
              </MDBox>
            </Stack>
            <Tooltip
              title={isContactFoundType ? `Marking the Alert as seen will remove your ability to add ${contacts[0]?.firstName} from this view.` : ""}
              placement="right"
            >
              <Switch
                checked={completed}
                onClick={e => {
                  e.stopPropagation()
                }}
                onChange={(e, checked) => updateCompletion(checked)}
              />
            </Tooltip>
          </Stack>
          {alert.contacts?.length > 0 && (
            <Stack direction="column">
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="caption">Related to {contacts[0]?.name}</MDTypography>
              </MDBox>
            </Stack>
          )}
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            {alert.contacts?.length > 0 && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <ForgeAvatarGroup contacts={contacts} enableImageClick={!isContactFoundType} />
              </MDBox>
            )}
            {alert.contacts?.length !== 0 && isContactFoundType && (
              <>
                <ForgeLoading loading={loading} loadingType="small" />
                {!loading && (
                  <MDButton
                    variant="text"
                    color={"info"}
                    onClick={() => createContactClick()}
                    size="small"
                    style={{ textTransform: "none", fontWeight: "400", padding: 0, fontSize: "0.875rem" }}
                  >
                    Add {contacts[0]?.name}&nbsp;<Icon>arrow_forward</Icon>
                  </MDButton>
                )}
              </>
            )}
          </MDBox>
        </MDBox>
      </Paper>
    </MDBox>
  )
}

// Setting default values for the props of AlertCard
AlertCard.defaultProps = {
  alert: null
}

export default AlertCard
