import { Button, Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, TextField, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import ExperienceTile from "../ExperienceTile"
import EducationTile from "../EducationTile"
import {
  BusinessCenterOutlined,
  FlagCircle,
  InfoRounded,
  LinkedIn,
  LocationOn,
  Refresh,
  SchoolOutlined,
  SummarizeOutlined,
  VolunteerActivismOutlined
} from "@mui/icons-material"
import { createUseStyles } from "react-jss"
import VolunteerWorkTile from "../VolunteerWorkTile"
import LocationTile from "../LocationTile"
import { useContext, useEffect, useMemo, useState } from "react"
import { AuthContext } from "context"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { LinkedInProfileContext } from "../../services/LinkedInProfileContext"
import { ContactContext } from "../../services/ContactContext"
import LinkedInOptionCard from "../LinkedInOptionCard"
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import ForgeCta from "forge/core/components/ForgeCta"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import ContactsApi from "../../services/api"
import { getLinkedInUrlProperties } from "../../utils"
import { triggerToastNotification } from "forge/shared/utils"
import LinkedInSearchDialog from "../LinkedInSearchDialog"
import MDButton from "components/MDButton"

const styles = createUseStyles({
  lightGray: { color: "darkgray" },
  tooltipSx: { fontSize: "20px !important" }
})

function ContactResearchCard({
  contact,
  handleOpenDetailDrawer
}: {
  contact: any
  handleOpenDetailDrawer: (knowledge: any, linkedInData?: any) => void
}) {
  const classes = styles()

  // Context
  const { companyKnowledge, locationKnowledge, summaryKnowledge, educationKnowledge, experienceKnowledge, volunteerWorkKnowledge } =
    useContext(KnowledgeContext)
  const { getCurrentUser } = useContext(AuthContext)
  const { profileOptions, selectProfile, setLinkedInUrl } = useContext(LinkedInProfileContext)
  const { searchLinkedInProfiles } = useContext(ContactContext)

  // Services
  let { user, userSettings, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  // State
  const [skipProfiles, setSkipProfiles] = useState<boolean>(false)
  const [url, setUrl] = useState<string>()
  const [reportReason, setReportReason] = useState<string>()
  const [openDialogReport, setOpenDialogReport] = useState(false)
  const handleOpenDialogReport = () => setOpenDialogReport(true)
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean[]>([])

  const [openLinkedInSearchDialog, setOpenLinkedInSearchDialog] = useState(false)
  const handleOpenLinkedInSearchDialog = () => setOpenLinkedInSearchDialog(true)
  const [company, setCompany] = useState<string>()
  const [location, setLocation] = useState<string>()
  const [keywords, setKeywords] = useState<string>()

  const possibleCategories = ["Offensive content", "Inaccurate summary", "Wrong person", "Incomplete summary", "Other (please explain)"]

  const updateCheckboxValue = (index: number, newValue: boolean) => {
    setSelectedCheckbox(prevState => {
      // Create a copy of the state array
      const newArray = [...prevState]
      // Update the value at the specified index
      newArray[index] = newValue
      // Return the new array to update the state
      return newArray
    })
  }

  useEffect(() => {
    setUrl("")
    if (contact?.linkedInSearchCache) {
      if (contact.linkedInSearchCache?.company) setCompany(contact.linkedInSearchCache?.company)
      if (contact.linkedInSearchCache?.location) setLocation(contact.linkedInSearchCache?.location)
      if (contact.linkedInSearchCache?.otherKeywords) setKeywords(contact.linkedInSearchCache?.otherKeywords)
    }
  }, [contact?.ref])

  useEffect(() => {
    // Falsy values ignored since enableLinkedInProfileSelection can be null or undefined,
    // that doesn't mean we shouldn't shop the options.
    if (contact?.enableLinkedInProfileSelection === false) setSkipProfiles(true)
    else setSkipProfiles(false)
  }, [contact?.enableLinkedInProfileSelection])

  function lacksData() {
    return (
      !(contact?.linkedInProfileData?.openai_summary?.text && contact?.linkedInProfileData?.openai_summary?.text.length > 0) &&
      !(companyKnowledge && companyKnowledge.length > 0) &&
      !(locationKnowledge && locationKnowledge.length > 0) &&
      !(summaryKnowledge && summaryKnowledge.length > 0) &&
      !(educationKnowledge && educationKnowledge.length > 0) &&
      !(experienceKnowledge && experienceKnowledge.length > 0) &&
      !(volunteerWorkKnowledge && volunteerWorkKnowledge.length > 0)
    )
  }

  const onSkipClick = async () => {
    // To reflect UI immediately
    setSkipProfiles(true)
    // Update field in backend
    await contactsApi.updateContact({
      ref: contact.ref,
      enableLinkedInProfileSelection: false
    })
  }

  function headerQuestion() {
    if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return `We're researching for ${contact.firstName}'s,\nplease wait a moment...`
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile) {
          return `Researching ${contact.firstName},\nplease wait a moment...`
        } else if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return `We're researching for ${contact.firstName}'s,\nplease wait a moment...`
        } else {
          return null
        }
      } else {
        return `What do you have in common with ${contact.firstName}?`
      }
    }
    if (profileOptions.length === 0 || skipProfiles) return `We couldn't find ${contact.firstName}'s profile. Paste the URL here.`
    return `Which LinkedIn Profile is ${contact.firstName}'s, if any?`
  }

  function renderBody() {
    if (!contact || contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return <ForgeLoading loading loadingType="inline" style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }} />
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile || contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return <ForgeLoading loading loadingType="inline" style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }} />
        } else {
          // Visit Profile
          return <ForgeCta cta="Visit LinkedIn Profile" icon={<LinkedIn />} onClick={() => window.open(contact?.linkedInUrl, "_blank")} />
        }
      } else {
        return <></>
      }
    }

    return (
      <>
        {profileOptions.length > 0 && !skipProfiles && (
          <>
            {profileOptions?.map(option => (
              <LinkedInOptionCard key={option?.formattedUrl} linkedInOption={option} onClick={() => onLinkedInOptionSelected(option)} />
            ))}
            <MDBox display="flex" justifyContent="center" textAlign="center">
              <MDButton variant="outlined" color="primary" onClick={() => onSkipClick()} style={{ marginBottom: 10 }} fullWidth>
                Skip
              </MDButton>
            </MDBox>
          </>
        )}

        <FormControl style={{ width: "100%" }} component="form" onSubmit={handleSubmit}>
          <TextField
            id="linkedIn"
            label="LinkedIn URL"
            variant="outlined"
            InputLabelProps={{ style: { color: "gray" } }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={`Navigate to the LinkedIn profile you want to copy. Once on the profile page, look at the browser's address bar at the top, where you'll see the URL of the profile. Click on the address bar to highlight the entire URL, then right-click and select "Copy," or simply press Ctrl+C on your keyboard. You can now paste the copied URL.`}
                  placement="bottom"
                >
                  <InfoRounded fontSize="small" />
                </Tooltip>
              )
            }}
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
        </FormControl>
      </>
    )
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (url) {
      await setLinkedInUrl(url)
    }
  }

  const onLinkedInOptionSelected = (linkedInOption: LinkedInProfileOption) => {
    selectProfile(linkedInOption)
  }

  const handleCloseDialogReport = async (result: boolean) => {
    setOpenDialogReport(false)
    if (result) {
      const { linkedInId } = getLinkedInUrlProperties(contact.linkedInUrl)
      const reportCategories = selectedCheckbox
        .map((_, index) => {
          return possibleCategories[index]
        })
        .filter(ele => ele !== null && ele !== undefined)
      const requestResult = await contactsApi.reportLinkedInSummary(linkedInId, reportReason, reportCategories)

      if (requestResult) {
        triggerToastNotification({
          notificationMessage: "Thanks for the report, we're let the admins know to start a review",
          notificationType: "success",
          customTimer: 3500
        })
        setReportReason(undefined)
      } else
        triggerToastNotification({
          notificationMessage: "There was an error submitting your report, please try again.",
          notificationType: "error",
          customTimer: 3500
        })
    }
  }

  const handleCloseLinkedInSearchDialog = async (result: boolean) => {
    setOpenLinkedInSearchDialog(false)
    if (result) {
      try {
        searchLinkedInProfiles({ company, location, otherKeywords: keywords })

        triggerToastNotification({
          notificationMessage: "Thanks for the help, we're starting a new search",
          notificationType: "success",
          customTimer: 3500
        })
        setCompany(undefined)
        setLocation(undefined)
        setKeywords(undefined)
      } catch (error) {
        triggerToastNotification({
          notificationMessage: "There was an error submitting your responses, please try again.",
          notificationType: "error",
          customTimer: 3500
        })
      }
    }
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Research" tooltipInfo={CARDS_TOOLTIPS.RESEARCH} />
        <ConfirmationDialog
          openDialog={openDialogReport}
          handleCloseDialog={handleCloseDialogReport}
          title="Tell us more to help improve this feature:"
          description="Select what fits the most your comment"
          confirmText="Report"
          denyVariant="outlined"
          extraComponent={
            <>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  {possibleCategories?.map((possibleAnswer: string, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox checked={selectedCheckbox[index]} onChange={() => updateCheckboxValue(index, !selectedCheckbox[index])} />}
                      label={possibleAnswer}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <TextField
                style={{ width: "100%", marginTop: "15px" }}
                id="outlined-multiline-static"
                label="Anything else we should know?"
                multiline
                rows={4}
                value={reportReason}
                onChange={e => setReportReason(e.target.value)}
              />
            </>
          }
        />
        {!skipProfiles && (
          <LinkedInSearchDialog
            openDialog={openLinkedInSearchDialog}
            handleCloseDialog={handleCloseLinkedInSearchDialog}
            contactName={contact?.firstName}
            company={company}
            setCompany={setCompany}
            location={location}
            setLocation={setLocation}
            keywords={keywords}
            setKeywords={setKeywords}
          />
        )}
        <Divider style={{ opacity: 1 }} />

        {contact?.firstName && (
          <MDBox display="flex" justifyContent="center" textAlign="center">
            <MDTypography variant="h6" textAlign="center" mb={1.5}>
              {headerQuestion()}
            </MDTypography>
            {!contact?.forgeInsightsState?.findingLinkedInProfiles && !contact?.linkedInUrl && profileOptions.length > 0 && (
              <Refresh style={{ marginLeft: "5px", marginTop: "3px", cursor: "pointer" }} onClick={handleOpenLinkedInSearchDialog} />
            )}
          </MDBox>
        )}
        {userSettings?.enableAiCareerSummaries &&
          contact?.linkedInProfileData?.openai_summary?.text &&
          contact?.linkedInProfileData?.openai_summary?.text.length > 0 && (
            <MDBox>
              <MDBox display="flex" alignItems="center" pr={0} lineHeight={1}>
                <SummarizeOutlined className={classes.lightGray} />
                <MDBox width={"385px"}>
                  <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                    {`${contact.firstName}'s Career Summary`}
                  </MDTypography>
                </MDBox>
                {!contact?.linkedInProfileData?.openai_summary?.moderationFlagged && (
                  <MDBox>
                    <Tooltip
                      title={`In case you see something that might be kind of offensive, you can report to Forge team by clicking this button.`}
                      placement="bottom"
                      onClick={handleOpenDialogReport}
                    >
                      <FlagCircle className={classes.tooltipSx} />
                    </Tooltip>
                  </MDBox>
                )}
              </MDBox>
              <MDTypography
                variant="body2"
                color="text"
                textAlign="justify"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenDetailDrawer(summaryKnowledge)}
              >
                {!contact?.linkedInProfileData?.openai_summary?.moderationFlagged ? (
                  parseKnowledge(summaryKnowledge, contact?.linkedInProfileData?.openai_summary?.text)
                ) : (
                  <>We’re reviewing {contact.firstName}’s summary</>
                )}
              </MDTypography>
              <Divider variant="middle" />
            </MDBox>
          )}

        {locationKnowledge && locationKnowledge.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <LocationOn className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Location
              </MDTypography>
            </MDBox>

            {locationKnowledge.map((e: any, index: number) => (
              <LocationTile key={index} location={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Education */}
        {contact?.linkedInProfileData?.education && contact?.linkedInProfileData?.education.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <SchoolOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Education
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.education?.map((e: any, index: number) => (
              <EducationTile key={e.id} education={e} knowledge={educationKnowledge} onClick={() => handleOpenDetailDrawer(educationKnowledge)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Experience  */}
        {contact?.linkedInProfileData?.experiences && contact?.linkedInProfileData?.experiences.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <BusinessCenterOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Experience
              </MDTypography>
            </MDBox>

            {companyKnowledge.map((e: any, index: number) => (
              <ExperienceTile key={index} knowledge={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            {contact?.linkedInProfileData?.experiences?.map((e: any, index: number) => (
              <ExperienceTile
                key={index}
                experience={e}
                knowledge={experienceKnowledge}
                onClick={() => handleOpenDetailDrawer(experienceKnowledge)}
              />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Volunteer Work   */}
        {contact?.linkedInProfileData?.volunteer_work && contact?.linkedInProfileData?.volunteer_work.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <VolunteerActivismOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Volunteer Work
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.volunteer_work?.map((e: any, index: number) => (
              <VolunteerWorkTile
                key={index}
                volunteerWork={e}
                knowledge={volunteerWorkKnowledge}
                onClick={() => handleOpenDetailDrawer(volunteerWorkKnowledge)}
              />
            ))}
          </MDBox>
        )}

        {renderBody()}
      </MDBox>
    </Card>
  )
}

export default ContactResearchCard
