import { createContext, useContext, useState, useEffect, useRef } from "react"
import { AuthContext, ContactsContext, EventsContext } from "context"
import { ForgeEvent } from "types/forge-event"
import { CalendarEvent } from "types/calendar/calendar-event"
import { Commitment } from "types/commitment"
import { CommitmentsContext } from "forge/commitments/services/CommitmentsContext"
import { MilestonesContext } from "forge/milestones/services/MilestonesContext"
import HomeFirestore from "./firestore"
import HomeApi from "./api"

interface AlertsContextType {
  alerts: ForgeEvent[]
  getAlertsForDay(targetDate: Date): ForgeEvent[]
  getGroupedAlerts(): { [key: string]: ForgeEvent[] }
  createContactFromAlert: (alert: ForgeEvent) => void
  updateAlert: (alert: ForgeEvent) => void
  loading: boolean
}

export const AlertsContext = createContext<AlertsContextType>({
  alerts: [],
  getAlertsForDay: (targetDate: Date): ForgeEvent[] => [],
  getGroupedAlerts: (): { [key: string]: ForgeEvent[] } => ({}),
  createContactFromAlert: () => {},
  updateAlert: () => {},
  loading: false
})

export const AlertsContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const contactsContextRef = useRef(getContacts) // Ref to hold the context value
  const { events } = useContext(EventsContext)
  const { commitments } = useContext(CommitmentsContext)
  const { milestones } = useContext(MilestonesContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const homeFirestore = new HomeFirestore(user, userProfileData, encryptionService)
  const homeApi = new HomeApi(user, userProfileData, encryptionService)

  // State
  const [alerts, setAlerts] = useState<ForgeEvent[]>([])
  const [alertEvents, setAlertEvents] = useState<CalendarEvent[]>([])
  const [alertCommitments, setAlertCommitments] = useState<Commitment[]>([])
  const [alertMilestones, setAlertMilestones] = useState<any[]>([])
  const [allAlerts, setAllAlerts] = useState<ForgeEvent[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)

    setAlerts([])
    const unsubscribe = homeFirestore.getAlerts((alerts: any) => {
      alerts.map((alert: any) => matchContacts(alert))
      setLoading(false)
      return setAlerts(alerts)
    })

    return () => unsubscribe()
  }, [isEncryptionInitialized])

  useEffect(() => {
    contactsContextRef.current = getContacts // Update the ref whenever the context value changes

    if (alerts.length > 0) {
      setAlerts(() => {
        for (let alert of alerts) alert = matchContacts(alert)
        return alerts
      })
    }
  }, [contacts])

  const matchContacts = (alert: any): any => {
    let foundContacts = contactsContextRef.current(alert.contactRefs.map((e: any) => e.id))
    alert.contacts = foundContacts.length > 0 ? foundContacts : alert.contacts
    return alert
  }

  useEffect(() => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero

    const sevenDaysFromNow = new Date()
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7)

    setAlertEvents(
      events.filter(event => {
        const eventDate = event.startDate.toDate()
        return eventDate >= currentDate && eventDate <= sevenDaysFromNow && event.isInvite
      })
    )
  }, [events])

  useEffect(() => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero

    const sevenDaysFromNow = new Date()
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7)

    setAlertCommitments(
      commitments.filter(commitment => {
        const eventDate = commitment.startDate.toDate()
        return eventDate >= currentDate && eventDate <= sevenDaysFromNow
      })
    )
  }, [commitments])

  useEffect(() => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero

    const sevenDaysFromNow = new Date()
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7)

    setAlertMilestones(
      milestones.filter(milestone => {
        const eventDate = milestone.startDate.toDate()
        return eventDate >= currentDate && eventDate <= sevenDaysFromNow
      })
    )
  }, [milestones])

  useEffect(() => {
    setAllAlerts([...alerts, ...alertEvents, ...alertCommitments, ...alertMilestones])
  }, [alerts, alertEvents, alertCommitments, alertMilestones])

  const getDateKey = (keyDate: Date) => {
    const year = keyDate.getFullYear()
    const month = (keyDate.getMonth() + 1).toString().padStart(2, "0")
    const day = keyDate.getDate().toString().padStart(2, "0")
    return `${year}-${month}-${day}` // Using date as key
  }

  const getAlertsForDay = (targetDate: Date, searchPastMeetings: boolean = false): ForgeEvent[] => {
    // Create a new Date object representing the start of the target day
    const startOfDay = new Date(targetDate)
    startOfDay.setHours(0, 0, 0, 0)

    // Create a new Date object representing the end of the target day
    const endOfDay = new Date(targetDate)
    endOfDay.setHours(23, 59, 59, 999)

    if (searchPastMeetings) {
      return allAlerts.filter(alert => {
        if ((alert as any).type !== "AlertType.contactFound") return false
        const alertDate = alert.startDate.toDate()
        const eventStartDate = alert?.eventStartDate?.toDate()
        const isSameDate = getDateKey(alertDate) === getDateKey(eventStartDate)
        return isSameDate ? false : eventStartDate <= startOfDay
      })
    }

    // Filter events that fall within the target day
    return allAlerts.filter(alert => {
      const alertDate = alert.startDate.toDate()
      if ((alert as any).type !== "AlertType.contactFound") return alertDate >= startOfDay && alertDate <= endOfDay
      const eventStartDate = alert?.eventStartDate?.toDate()
      return eventStartDate >= startOfDay ? alertDate >= startOfDay && alertDate <= endOfDay : eventStartDate >= startOfDay
    })
  }

  const getGroupedAlerts = (): { [key: string]: ForgeEvent[] } => {
    // Get today's date
    const today = new Date()
    const data: { [key: string]: ForgeEvent[] } = { past: getAlertsForDay(today, true) }
    for (let i = 0; i < 7; i++) {
      const dateKey = getDateKey(today)

      data[dateKey] = getAlertsForDay(today)
      today.setDate(today.getDate() + 1)
    }
    return data
  }

  const createContactFromAlert = async (alert: ForgeEvent) => {
    await homeApi.createContactFromAlert(alert)
    setAlerts(alerts.filter(e => e.ref.id !== alert.ref.id))
  }

  const updateAlert = async (alert: ForgeEvent) => {
    await homeApi.updateAlert(alert)
  }

  return (
    <AlertsContext.Provider
      value={{
        alerts: allAlerts,
        getAlertsForDay,
        getGroupedAlerts,
        createContactFromAlert,
        updateAlert,
        loading
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}
