import { Timestamp } from "firebase/firestore"
import { Crm, crmStringMap } from "types/pipeline/crm"

export class UserPipelineConfig {
  isEnabled: boolean
  connectionId?: string | null
  integration?: Crm | null
  organizationId?: string | null
  enableDate?: Date | null
  lastInteraction?: Date | null
  lastSync?: Date | null
  pipelineLoading: boolean
  loadingProcess?: number | null

  constructor({
    isEnabled = false,
    connectionId = null,
    integration = null,
    organizationId = null,
    enableDate = null,
    lastInteraction = null,
    lastSync = null,
    pipelineLoading = false,
    loadingProcess = null
  }: {
    isEnabled?: boolean
    connectionId?: string | null
    integration?: Crm | null
    organizationId?: string | null
    enableDate?: Date | null
    lastInteraction?: Date | null
    lastSync?: Date | null
    pipelineLoading?: boolean
    loadingProcess?: number | null
  } = {}) {
    this.isEnabled = isEnabled
    this.connectionId = connectionId
    this.integration = integration
    this.organizationId = organizationId
    this.enableDate = enableDate
    this.lastInteraction = lastInteraction
    this.lastSync = lastSync
    this.pipelineLoading = pipelineLoading
    this.loadingProcess = loadingProcess
  }

  copyWith({
    isEnabled,
    connectionId,
    integration,
    organizationId,
    enableDate,
    lastInteraction,
    lastSync,
    pipelineLoading,
    loadingProcess
  }: {
    isEnabled?: boolean
    connectionId?: string | null
    integration?: Crm | null
    organizationId?: string | null
    enableDate?: Date | null
    lastInteraction?: Date | null
    lastSync?: Date | null
    pipelineLoading: boolean
    loadingProcess?: number | null
  }): UserPipelineConfig {
    return new UserPipelineConfig({
      isEnabled: isEnabled ?? this.isEnabled,
      connectionId: connectionId ?? this.connectionId,
      integration: integration ?? this.integration,
      organizationId: organizationId ?? this.organizationId,
      enableDate: enableDate ?? this.enableDate,
      lastInteraction: lastInteraction ?? this.lastInteraction,
      lastSync: lastSync ?? this.lastSync,
      pipelineLoading: pipelineLoading ?? this.pipelineLoading,
      loadingProcess: loadingProcess ?? this.loadingProcess
    })
  }

  toMap({ toFirestore = true }: { toFirestore?: boolean } = {}): {
    [key: string]: any
  } {
    return {
      isEnabled: this.isEnabled,
      connectionId: this.connectionId,
      integration: this.integration,
      organizationId: this.organizationId,
      enableDate: this.enableDate !== null ? (toFirestore ? Timestamp.fromDate(this.enableDate) : this.enableDate.getTime()) : null,
      lastInteraction:
        this.lastInteraction !== null ? (toFirestore ? Timestamp.fromDate(this.lastInteraction) : this.lastInteraction.getTime()) : null,
      lastSync: this.lastSync !== null ? (toFirestore ? Timestamp.fromDate(this.lastSync) : this.lastSync.getTime()) : null,
      pipelineLoading: this.pipelineLoading,
      loadingProcess: this.loadingProcess
    }
  }

  static fromMap(map: { [key: string]: any }): UserPipelineConfig {
    return new UserPipelineConfig({
      isEnabled: map?.isEnabled ?? false,
      connectionId: map?.connectionId,
      integration: Object.keys(crmStringMap).find(key => crmStringMap[key as Crm] === map?.integration) as Crm | undefined,
      organizationId: map?.organizationId,
      enableDate:
        map?.enableDate !== null
          ? map?.enableDate instanceof Timestamp
            ? (map?.enableDate as Timestamp).toDate()
            : new Date(map?.enableDate)
          : new Date(),
      lastInteraction:
        map?.lastInteraction !== null
          ? map?.lastInteraction instanceof Timestamp
            ? (map?.lastInteraction as Timestamp).toDate()
            : new Date(map?.lastInteraction)
          : new Date(),
      lastSync:
        map?.lastSync !== null ? (map?.lastSync instanceof Timestamp ? (map?.lastSync as Timestamp).toDate() : new Date(map?.lastSync)) : new Date(),
      pipelineLoading: map?.pipelineLoading ?? false,
      loadingProcess: map?.loadingProcess ?? false
    })
  }
}
