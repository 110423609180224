import { DndContext, DragEndEvent, useSensor, useSensors } from "@dnd-kit/core"
import { Autocomplete, Avatar, Box, createFilterOptions, Divider, Stack, TextField } from "@mui/material"
import MDBox from "components/MDBox"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { Droppable } from "./droppable"
import { MailOutlineRounded, PersonAdd, PersonSearchOutlined } from "@mui/icons-material"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { AuthContext, ContactsContext } from "context"
import { AttendeeType, ForgeAttendee, InvitationStatus } from "types/calendar/forge-attendee"
import { createUseStyles } from "react-jss"
import { DraggableChip } from "./draggableChip"
import { MouseSensor, TouchSensor } from "./draggable"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import CreateContactDrawer from "forge/people/contacts/components/CreateContactDrawer"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import { isEmailFormat, splitString } from "forge/core/utilities"
import SelectContactDrawer from "forge/people/contacts/components/SelectContactDrawer"
import ContactsApi from "forge/people/contacts/services/api"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  formColor: { alignSelf: "center", height: "1.5em", width: "1.5em" },
  formTile: { display: "flex", alignItems: "flex-end", marginBottom: "12px" },
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    }
  },
  accordionRoot: {
    "&:before": {
      display: "none"
    }
  }
})

export function AttendeesSelector({
  attendees = [],
  setAttendees,
  attendeesResearch = [],
  setAttendeesResearch,
  initialAttendees = []
}: {
  attendees: ForgeAttendee[]
  setAttendees?: Dispatch<SetStateAction<ForgeAttendee[]>>
  attendeesResearch: ForgeAttendee[]
  setAttendeesResearch?: Dispatch<SetStateAction<ForgeAttendee[]>>
  initialAttendees?: string[]
}) {
  // UI
  const classes = styles()
  const customSensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // Context
  const { contacts } = useContext(ContactsContext)
  const [initialCreateValue, setInitialCreateValue] = useState<string>("")
  const [inviteSearchText, setInviteSearchText] = useState<string>("")
  const [inviteInputLoading, setInviteInputLoading] = useState<boolean>(false)
  const [researchSearchText, setResearchSearchText] = useState<string>("")
  const [researchInputLoading, setResearchInputLoading] = useState<boolean>(false)
  const [invokedFrom, setInvokedFrom] = useState<string>(null)
  const [emailForInvite, setEmailForInvite] = useState<string>(null)
  const [openCreateContactDrawer, setOpenCreateContactDrawer] = useState(false)
  const [openAddToContactDrawer, setOpenAddToContactDrawer] = useState(false)

  const { getCurrentUser } = useContext(AuthContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  const handleOpenCreateDrawer = (isResearch = false) => {
    setInvokedFrom(isResearch ? "research" : "invite")
    setInitialCreateValue(isResearch ? researchSearchText : inviteSearchText)
    setOpenCreateContactDrawer(true)
  }
  const handleCloseCreateDrawer = () => setOpenCreateContactDrawer(false)

  // Add email to contact
  const handleOpenAddToContactDrawer = (isResearch = false) => {
    setOpenAddToContactDrawer(true)
  }
  const handleCloseAddToContactDrawer = () => setOpenAddToContactDrawer(false)

  useEffect(() => {
    if (initialAttendees) {
      const shouldBeCurrentContacts = contacts.filter(contact => initialAttendees.includes(contact.id))
      for (const contact of shouldBeCurrentContacts) {
        if (!attendees.some(attendee => contact.emailStrings.includes(attendee.emailAddress))) onAttendeeAdded(null, [...attendees, contact])
      }
    }
  }, [initialAttendees])

  // State
  const filterContacts = () => {
    let options: ForgeAttendee[] = []
    let contactsWithEmails = contacts.filter((contact: any) => contact.emailStrings?.length > 0)
    for (const contact of contactsWithEmails) {
      for (const email of contact.emailStrings) {
        if (email && email.trim()) {
          if (
            !options.some(attendee => {
              return attendee.contact.ref.id === contact.ref.id && attendee.name === contact.name && attendee.emailAddress === email.trim()
            })
          ) {
            options.push(
              new ForgeAttendee({
                contact: contact,
                type: AttendeeType.normal,
                name: contact.name,
                ref: contact.ref,
                emailAddress: email.trim(),
                status: InvitationStatus.none
              })
            )
          }
        }
      }
    }

    options = options.filter(option => user.email !== option.emailAddress)

    if (emailForInvite) {
      const attendee = contacts.find(contact => contact.id === emailForInvite)
      if (invokedFrom === "research") {
        onResearchAttendeeAdded(null, [...attendeesResearch, attendee])
        setResearchSearchText("")
        setResearchInputLoading(false)
      } else {
        onAttendeeAdded(null, [...attendees, attendee])
        setInviteSearchText("")
        setInviteInputLoading(false)
      }
      setEmailForInvite(null)
    }

    return options
  }
  const [attendeesOptions, setAttendeesOptions] = useState<any[]>(filterContacts)
  useEffect(() => {
    setAttendeesOptions(filterContacts())
  }, [contacts])

  const filterOptions = createFilterOptions({
    matchFrom: "any", // Can be 'anywhere' or 'start'
    stringify: (option: any) => `${option.name} ${option.emailAddress}`
  })

  const onAttendeeAdded = (_: any, newValue: any[], skipResearchAssignment = false, customAdded?: string) => {
    let newResearchAttendees: any[] = []
    let newAttendees = newValue
      .map(value => {
        if (value instanceof ForgeAttendee) {
          return value
        }

        if (value?.emailStrings?.length <= 0) {
          newResearchAttendees.push(value)
          return undefined
        }

        return new ForgeAttendee({
          contact: value,
          type: AttendeeType.normal,
          ref: value.ref,
          name: value.name,
          emailAddress: customAdded ?? value?.emailStrings?.[0] ?? "",
          status: InvitationStatus.none
        })
      })
      .filter(e => e)

    if (!skipResearchAssignment) {
      onResearchAttendeeAdded(null, [...attendeesResearch, ...newResearchAttendees])
    }
    setInviteSearchText("")
    return setAttendees(newAttendees)
  }

  const onAttendeeRemoved = ({ attendeeRemovedId, attendeeRemoved }: { attendeeRemovedId?: string; attendeeRemoved?: any }) =>
    setAttendees(prevContacts => prevContacts.filter(attendee => attendee.emailAddress !== (attendeeRemoved.emailAddress)))

  const onResearchAttendeeAdded = (event: any, newValue: any[]) => {
    setAttendeesResearch(
      newValue.map(value => {
        if (value instanceof ForgeAttendee) {
          return value
        }

        return new ForgeAttendee({
          contact: value,
          type: AttendeeType.research,
          ref: value.ref,
          name: value.name,
          emailAddress: value.emailStrings?.length > 0 ? value.emailStrings[0] : "",
          status: InvitationStatus.none
        })
      })
    )
    setResearchSearchText("")
  }

  const onResearchAttendeeRemoved = ({ attendeeRemovedId, attendeeRemoved }: { attendeeRemovedId?: string; attendeeRemoved?: any }) =>
    setAttendeesResearch(prevContacts => prevContacts.filter(attendee => attendee.contact.id !== (attendeeRemovedId ?? attendeeRemoved.contact.id)))

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      if (over.id === "emailAttendees") {
        onResearchAttendeeRemoved({ attendeeRemovedId: active.data.current?.id })
        if (attendees.some(e => e.contact?.id === active.data.current?.id)) return
        onAttendeeAdded(undefined, [...attendees, active.data.current], true)
      } else if (over.id === "researchAttendees") {
        onAttendeeRemoved({ attendeeRemovedId: active.data.current?.id })
        if (attendeesResearch.some(e => e.contact?.id === active.data.current?.id)) return
        onResearchAttendeeAdded(undefined, [...attendeesResearch, active.data.current])
      }
    }
  }

  const handleContactCreation = (email: string) => {
    if (invokedFrom === "research") setResearchInputLoading(true)
    else setInviteInputLoading(true)
    setEmailForInvite(email)
  }

  const handleContactNewEmailAssociation = async (contact: string) => {
    handleCloseAddToContactDrawer()
    if (invokedFrom === "research") {
      setResearchInputLoading(true)
      await handleAddNewEmailToContact(contact, researchSearchText)
      setResearchInputLoading(false)
      onResearchAttendeeAdded(null, [...attendeesResearch, contact])
      setResearchSearchText("")
      return
    }

    setInviteInputLoading(true)
    await handleAddNewEmailToContact(contact, inviteSearchText)
    setInviteInputLoading(false)
    onAttendeeAdded(null, [...attendees, contact], false, inviteSearchText)
    setInviteSearchText("")
  }

  const addToExistingContactButton = (isResearch: boolean) => {
    const emailValidation = isResearch ? isEmailFormat(researchSearchText) : isEmailFormat(inviteSearchText)
    if (!emailValidation) return null

    const handleClick = () => {
      setInvokedFrom(isResearch ? "research" : "invite")
      handleOpenAddToContactDrawer(true)
    }

    return (
      <MDButton variant="outlined" color="primary" onClick={handleClick}>
        Add To Existing Person
      </MDButton>
    )
  }

  const handleAddNewEmailToContact = async (contact: any, newEmail: string) => {
    let fullName: string = ""
    fullName += contact?.firstName
    fullName += " "
    fullName += contact?.lastName ?? ""

    let updatedContact: any = {
      ref: contact.ref,
      title: contact?.jobTitle,
      name: fullName.trim(),
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      company: contact?.company,
      jobTitle: contact?.jobTitle,
      linkedInUrl: contact?.linkedInUrl,
      birthdayDay: contact?.birthdayDay,
      birthdayMonth: contact?.birthdayMonth,
      birthdayYear: contact?.birthdayYear,
      phones: contact?.phones,
      addresses: contact?.addresses,
      emailStrings: [...contact?.emailStrings, newEmail],
      emails: [...contact?.emails, { favorite: false, fieldType: "email", type: "personal", value: newEmail }]
    }

    await contactsApi.updateContact(updatedContact)
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={customSensors}>
      <MDBox className={classes.formTile}>
        <CreateContactDrawer
          openDrawer={openCreateContactDrawer}
          handleCloseDrawer={handleCloseCreateDrawer}
          enableNavigation={false}
          initialEmail={isEmailFormat(initialCreateValue) ? initialCreateValue : ""}
          initialFirstName={isEmailFormat(initialCreateValue) ? "" : splitString(initialCreateValue, " ")[0] ?? ""}
          initialLastName={isEmailFormat(initialCreateValue) ? "" : splitString(initialCreateValue, " ")[1] ?? ""}
          initialCompany={isEmailFormat(initialCreateValue) ? "" : splitString(initialCreateValue, " ")[2] ?? ""}
          invokedFromInvite
          setEmailForInvite={handleContactCreation}
        />
        <MailOutlineRounded className={classes.formIcon} />

        <SelectContactDrawer
          openDrawer={openAddToContactDrawer}
          handleCloseDrawer={handleCloseAddToContactDrawer}
          onContactSelected={contact => handleContactNewEmailAssociation(contact)}
        />

        <Autocomplete
          multiple
          filterSelectedOptions
          id="attendees-select"
          sx={{ width: 300 }}
          style={{ flex: 1 }}
          value={attendees}
          options={attendeesOptions}
          autoHighlight
          getOptionLabel={option => (option?.contact?.ref?.id ? `${option?.contact?.ref.id} ${option.emailAddress}` : "")}
          filterOptions={filterOptions}
          renderTags={(value: ForgeAttendee[]) => {
            return (
              <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                {value.map((attendee, index) => (
                  <DraggableChip
                    key={index}
                    attendee={attendee}
                    onAttendeeRemoved={attendeeRemoved => onAttendeeRemoved({ attendeeRemoved: attendeeRemoved })}
                  />
                ))}
                <Avatar style={{ background: "cornflowerblue", marginTop: 4, width: "32px", height: "32px", cursor: "pointer" }}>
                  <PersonAdd fontSize="small" style={{ color: "white" }} />
                </Avatar>
              </Stack>
            )
          }}
          onChange={(event, newValue) => onAttendeeAdded(event, newValue)}
          renderOption={(props, option) => (
            <Box key={option?.ref?.id} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <MDBox mr={2}>
                <ForgeContactAvatar contact={option.contact} enableScoreBadge={false} />
              </MDBox>
              <Stack direction="column">
                {option.name}
                <MDTypography variant="body2" fontSize="small" style={{ color: "gray", lineHeight: "1rem" }}>
                  {option.emailAddress}
                </MDTypography>
              </Stack>
            </Box>
          )}
          inputValue={inviteSearchText}
          renderInput={params => (
            <Droppable id={"emailAttendees"}>
              <TextField
                {...params}
                className={classes.root}
                label="Invite people by email"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
                onChange={e => setInviteSearchText(e.target.value)}
              />
            </Droppable>
          )}
          noOptionsText={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              No Person found
              {addToExistingContactButton(false)}
              <MDButton variant="outlined" color="primary" onClick={() => handleOpenCreateDrawer()}>
                Add New Person
              </MDButton>
            </Box>
          }
          disabled={inviteInputLoading}
        />
        <ForgeLoading loading={inviteInputLoading} loadingType="small" style={{ paddingBottom: "8px" }} />
      </MDBox>
      <Divider />
      <MDBox className={classes.formTile}>
        <PersonSearchOutlined className={classes.formIcon} />
        <Autocomplete
          multiple
          filterSelectedOptions
          id="research-attendees-select"
          sx={{ width: 300 }}
          style={{ flex: 1 }}
          autoHighlight
          value={attendeesResearch}
          options={contacts}
          getOptionDisabled={option =>
            attendees.find(attendee => attendee.contact?.id === option.id) !== undefined ||
            attendeesResearch.find(attendee => attendee.contact?.id === option.id) !== undefined
          }
          getOptionLabel={option => option?.ref?.id ?? ""}
          filterOptions={filterOptions}
          renderTags={value => {
            return (
              <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                {value.map((attendee, index) => (
                  <DraggableChip
                    key={index}
                    attendee={attendee}
                    onAttendeeRemoved={attendeeRemoved => onResearchAttendeeRemoved({ attendeeRemoved: attendeeRemoved })}
                  />
                ))}
                <Avatar style={{ background: "cornflowerblue", marginTop: 4, width: "32px", height: "32px", cursor: "pointer" }}>
                  <PersonAdd fontSize="small" style={{ color: "white" }} />
                </Avatar>
              </Stack>
            )
          }}
          onChange={onResearchAttendeeAdded}
          renderOption={(props, option) => (
            <Box key={option?.ref?.id} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <MDBox mr={2}>
                <ForgeContactAvatar contact={option} enableScoreBadge={false} />
              </MDBox>
              {option.name}
            </Box>
          )}
          inputValue={researchSearchText}
          renderInput={params => (
            <Droppable id={"researchAttendees"}>
              <TextField
                {...params}
                className={classes.root}
                label="Add people for research only"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
                onChange={e => setResearchSearchText(e.target.value)}
              />
            </Droppable>
          )}
          noOptionsText={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              No Person found
              {addToExistingContactButton(true)}
              <MDButton variant="outlined" color="primary" onClick={() => handleOpenCreateDrawer(true)}>
                Add New Person
              </MDButton>
            </Box>
          }
          disabled={researchInputLoading}
        />
        <ForgeLoading loading={researchInputLoading} loadingType="small" style={{ paddingBottom: "8px" }} />
      </MDBox>
    </DndContext>
  )
}
